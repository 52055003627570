import { Map } from 'immutable';

let initPagerState = Map({
  page: 0,
  pageSize: 10,
  totalPagees: 1,
  totalItemCount: 0,
});

export {
  initPagerState
}

export default function pagerReducerFor(prefix, initState) {
  if (initState !== null && typeof initState === 'object') {
    initPagerState = initPagerState.merge(initState);
  }

  const pagerReducer = (state = initPagerState, action) => {
    switch (action.type) {
      case `${prefix}_SET_PAGER`:
        return applySetPager(state, action);

      default:
        return state;
    }
  };
  return pagerReducer;
}

function applySetPager(state, action) {
  return state.merge(action.pagination);
}


