import React  from 'react';
import { ThemeProvider } from 'styled-components';
import AppStyleHolder from './AppStyle';
import { Provider } from 'react-redux';

import themes from './settings/themes';
import { themeConfig } from './settings';
import { store, history } from './store/store';
import PublicRoutes from './routers/Router';

const App = () => (
  <ThemeProvider theme={themes[themeConfig.theme]}>
    <AppStyleHolder>
      <Provider store={store}>
        <PublicRoutes history={history} />
      </Provider>
    </AppStyleHolder>
  </ThemeProvider>
);

export default App;
