import axios  from '../../settings/serviceConfig';
// import {getParams} from "../../helpers/utility";

const fetchList = () => {
  // const param = getParams(conditions)
  // return axios.get(`${context}/meta/data${param}`);
  return axios.get('/device-status');
};

export {
  fetchList,
};
