import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Layout} from 'antd';
import appActions from '../../../store/app/actions';
import TopbarUser from './TopbarUser';
import TopbarWrapper from './topbar.style';
import themes from '../../../settings/themes/index';
import {themeConfig} from '../../../settings/index';

const {Header} = Layout;
const {toggleCollapsed} = appActions;
const customizedTheme = themes[themeConfig.theme];

class Topbar extends Component {

  render() {
    const {toggleCollapsed} = this.props;
    const collapsed = this.props.collapsed && !this.props.openDrawer;
    const styling = {
      background: customizedTheme.backgroundColor,
      position: 'fixed',
      width: '100%',
      height: 60,
    };
    return (
      <TopbarWrapper>
        <Header style={styling} className={collapsed ? 'teoTopbar collapsed' : 'teoTopbar'}>
          <div className="teoLeft">
            <i
              className={collapsed ? 'icon-indent-increase' : 'icon-indent-decrease'}
              style={{color: customizedTheme.textColor, cursor: 'pointer'}}
              onClick={toggleCollapsed}
            />
          </div>

          <ul className="teoRight">
            <li onClick={() => this.setState({selectedItem: 'user'})} className="teoUser">
              <TopbarUser
                goProfile={this.props.goProfile}
              />
            </li>
          </ul>
        </Header>
      </TopbarWrapper>
    );
  }
}

export default connect(
  (state) => ({
    ...state.app,
  }),
  {toggleCollapsed},
)(Topbar);
