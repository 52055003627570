/**
 * Define Action Type & Action Creator
 */
const scope = 'DASHBOARD';
const actions = {
	/**
	 * Define Action Types
	 */
	INIT           : `${scope}_INIT`,
	LOUNGE         : `${scope}_LOUNGE`,
	LOUNGE_SUCCESS : `${scope}_LOUNGE_SUCCESS`,

	/**
	 * Define Action Creators
	 */
	initialize: () => ({
		type: actions.INIT,
	}),

	lounge: () => ({
		type: actions.LOUNGE,
	}),

	loungeSuccess: (payload) => ({
		type: actions.LOUNGE_SUCCESS,
		payload,
	}),

};

export default actions;
export {
	scope
}
