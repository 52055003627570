import { takeEvery, put, call, spawn } from 'redux-saga/effects';
import {spinnerActions} from '../../components/common/spinner/index';
import {errorMessage} from 'gurmwi-share-front/share/feedback/Message/message';

import actions from './actions';
import * as service from './serviceApi';

export function* fetchLoungeSaga(action) {
	yield put(spinnerActions.start());
	try {
		const response = yield call(service.lounge);
		yield put(actions.loungeSuccess(response.data));
		yield put(spinnerActions.success());
	} catch (error) {
		yield put(spinnerActions.failure());
		errorMessage('메뉴 조회');
	}
}

export function* watchSaga() {
	yield takeEvery(actions.LOUNGE, fetchLoungeSaga);
}

export default function* rootSaga() {
	yield spawn(watchSaga);
}
