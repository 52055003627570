import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Layout} from 'antd';
import {Debounce} from 'react-throttle';
import WindowResizeListener from 'react-window-size-listener';
import {ThemeProvider} from 'styled-components';
import {getToken, setToken} from 'gurmwi-share-front/share/helpers/utility';
import appActions from '../../../store/app/actions';
import {indexSelector as appSelector} from '../../../store/app/selectors';
import authActions from '../../../store/auth/actions';
import AppRouter from '../../../routers/AppRouter';
import {Spinner} from '../../../components/common/spinner';
import {appRootPath, siteConfig, themeConfig} from '../../../settings';
// import {getDefaultPath} from "../../../helpers/urlSync";
import themes from '../../../settings/themes';
// import tokenActions from '../../../store/token/actions';
import {isAllowed} from '../Sidebar/menu';

import Topbar from '../Topbar/Topbar';
import Sidebar from '../Sidebar/Sidebar';
import CommonStyleHolder from './commonStyle';
import './global.css';
import {setHeaders} from "../../../settings/serviceConfig";
import {profileActions} from "site-ui-module";
import {indexSelector as authSelector} from "../../../store/auth/selectors";

const {Content, Footer} = Layout;

export class LayoutContainer extends Component {
    //
    componentDidMount() {
        return this.initialize();
    }

    initialize = async () => {
        //
        const searchParams = new URLSearchParams(this.props.location.search);
        let token = searchParams.get('token');

        if (token){
            let refreshToken = searchParams.get('refresh');
            setToken(token, refreshToken);
            this.props.appActions.gotoAppStore();
        } else {
            token = getToken();
        }
        if (token) {
            setHeaders();
            // await this.props.tokenActions.checkToken(token);
            await this.props.authActions.checkAuthorization(token,"",this.resumeDeferredTasks);
            // this.checkRole(this.props.authForm.positionAuthority)
            // this.props.appActions.gotoAppStore();

            /*FIXME 사이트 호출*/
            //this.props.appActions.fetchMembership();

            // this.props.appActions.getGurmwiMeta((meta) => {
            //     this.props.authActions.checkAuthorization((authority) => {
            // //             // this.props.tokenActions.checkToken((authority) => {
            //             this.checkRole(authority);
            //         }, () => {
            // //             // FIXME 화면 개발시 주석 처리
            // //             // this.gotoFrontGatewayUrl(meta);
            //         }
            //     );
            // }
            // );
        } else {
            //await this.goLogin();
            // this.props.appActions.getGurmwiMeta((meta) => {
                // FIXME 화면 개발시 주석 처리
                // this.gotoFrontGatewayUrl(meta);
            // });
            // await this.tokenInvalidated();
        }
        // const type = getType();
        // 파일타입 조회
        // if (type === null) {
        //     this.props.appActions.findFileType();
        // }
    };

    /*gotoGuest = () => {
      const guestPath = `${appRootPath}/${window.gurmwi.nest.ROUTE_GUEST}`;
      this.props.history.push(guestPath);
    };*/

    checkRole = (authority, meta) => {
        const pathname = window.location.pathname.replace(/\/$/, '');
        const paths = pathname.substr(1).split('/');
        if (pathname === appRootPath) {
            paths.push('/');
        }
        authority = authority && authority.authorityType;
        if (isAllowed(paths, authority)) {
            this.resumeDeferredTasks();
            if (pathname === appRootPath) {
                this.props.appActions.gotoAppStore();
            }
        } else {
            // FIXME 화면 개발시 주석 처리
            // this.gotoFrontGatewayUrl(meta);
            // this.props.history.push(appRootPath);
        }
    };

    resumeDeferredTasks = async () => {
        const deferredTasks = this.props.appForm.deferredTasks;
        deferredTasks.forEach(async (deferredTask) => {
            await deferredTask();
        });
        this.props.appActions.emptyDeferredTasks();
    };

    gotoFrontGatewayUrl = (meta) => {
        if (meta && meta.virtualHome && meta.virtualHome.frontGatewayUrl) {
            window.location.href = meta.virtualHome.frontGatewayUrl;
            //window.location.href = meta.virtualHome.frontGatewayUrl.replace('8023', '3000');
        }
    };

    goProfile = () => {
        this.props.history.push(`${appRootPath}/profile`);
    };

    render() {
        const {url} = this.props.match;
        const {height} = this.props.appForm;
        const appHeight = window.innerHeight;
        return (
            <Spinner>
                <ThemeProvider theme={themes[themeConfig.theme]}>
                    <CommonStyleHolder>
                        <Layout style={{height: appHeight}}>
                            <Debounce time="1000" handler="onResize">
                                <WindowResizeListener
                                    onResize={(windowSize) => this.props.appActions.toggleAll(windowSize.windowWidth, windowSize.windowHeight)}
                                />
                            </Debounce>

                            <Topbar
                                url={url}
                                // goProfile={this.goProfile}
                            />

                            <Layout style={{flexDirection: 'row', overflowX: 'hidden'}}>
                                <Sidebar url={url}/>
                                <Layout
                                    className="teoContentMainLayout"
                                    style={{
                                        height: height,
                                    }}
                                >
                                    <Content
                                        className="teoContent"
                                        style={{
                                            padding: '60px 0 0 0',
                                            flexShrink: '0',
                                            background: '#F0F0F0',
                                            position: 'relative',
                                        }}
                                    >
                                        <AppRouter url={url}/>
                                    </Content>

                                    <Footer
                                        style={{
                                            background: '#ffffff',
                                            textAlign: 'center',
                                            borderTop: '1px solid #ededed',
                                        }}
                                    >
                                        {siteConfig.footerText}
                                    </Footer>

                                </Layout>
                            </Layout>
                        </Layout>
                    </CommonStyleHolder>
                </ThemeProvider>
            </Spinner>
        );
    }
}

const stateToProps = (state) => ({
    appForm: appSelector.form(state),
    authForm: authSelector.form(state),
});

const dispatchToProps = (dispatch) => ({
    appActions: bindActionCreators(appActions, dispatch),
    authActions: bindActionCreators(authActions, dispatch),
    profileActions: bindActionCreators(profileActions, dispatch),
});

export default connect(
    stateToProps,
    dispatchToProps,
)(LayoutContainer);
