import React, { Component } from 'react';

const rtl = document.getElementsByTagName('html')[0].getAttribute('dir');

const withDirection = (Comp) => {
  return class extends Component {
    render() {
      const {...props} = this.props;
      return <Comp {...props} data-rtl={rtl} />;
    }
  }
};

export default withDirection;
export { rtl };
