import axios, {tokenUrl} from '../../settings/serviceConfig';

const authorization = () => {
    return axios.get(`${tokenUrl}/token`);
};

const logout = () => {
    return axios.post(`${tokenUrl}/logout`);
};

export {
    authorization,
    logout,
};