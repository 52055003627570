import {all} from 'redux-saga/effects';

import profileSagas from 'site-ui-module/src/store/profile/sagas';

import appSagas from './app/sagas';
import authSagas from './auth/sagas';
import dashboardSagas from './dashboard/sagas';

import deviceStatusSagas from "./deviceStatus/sagas";
import workplaceStatusSagas from "./workplaceDeviceStatus/sagas";
import deviceSearchSagas from "./deviceSearch/sagas";
import workerSagas from "./worker/sagas";
import edgeboxSagas from "./edgebox/sagas";


export default function* rootSaga() {
  yield all([
    profileSagas(),
    appSagas(),
    authSagas()  ,
    dashboardSagas(),

    deviceStatusSagas(),
    workplaceStatusSagas(),
    deviceSearchSagas(),
    workerSagas(),
    edgeboxSagas(),
  ]);
}
