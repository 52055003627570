import { DatePicker as DatePick } from 'antd';
import DatePickerStyle from './datePicker.style';

const DatePicker = DatePickerStyle(DatePick);
const WeekPicker = DatePickerStyle(DatePick.WeekPicker);
const MonthPicker = DatePickerStyle(DatePick.MonthPicker);
const DateRangepicker = DatePickerStyle(DatePick.RangePicker);


export default DatePicker;
export {
	WeekPicker,
	MonthPicker,
	DateRangepicker
};
