import {combineReducers} from "redux";
import {List, Map} from "immutable";

import actions from './actions';
import {convertNestedByArray} from "../../helpers/utility";
import pagerReducerFor from "../common/pagination/reducerFor";

/**
 * Set Initial State
 */

const initSearch = Map({
    deviceId: '',
    direction: 'DESC',
});
const initDeviceInfo = Map({
    tableVisible: '',
    loading: false,
    list: List(),
});

const initIndexState = Map({
    edgeboxSearch: Map().merge(initSearch),
    smartwatchSearch: Map().merge(initSearch),
    kioskSearch: Map().merge(initSearch),

    edgeboxInfo: Map().merge(initDeviceInfo),
    smartwatchInfo: Map().merge(initDeviceInfo),
    kioskInfo: Map().merge(initDeviceInfo),
});

const edgeboxSearch = convertNestedByArray(initIndexState.get('edgeboxSearch').toJS(), 'edgeboxSearch');
const smartwatchSearch = convertNestedByArray(initIndexState.get('smartwatchSearch').toJS(), 'smartwatchSearch');
const kioskSearch = convertNestedByArray(initIndexState.get('kioskSearch').toJS(), 'kioskSearch');

const edgeboxInfo = convertNestedByArray(initIndexState.get('edgeboxInfo').toJS(), 'edgeboxInfo');
const smartwatchInfo = convertNestedByArray(initIndexState.get('smartwatchInfo').toJS(), 'smartwatchInfo');
const kioskInfo = convertNestedByArray(initIndexState.get('kioskInfo').toJS(), 'kioskInfo');

/**
 * Define Reducer
 */
export function indexReducer(state = initIndexState, action) {
    switch (action.type) {
        //
        case actions.INIT_SEARCH:
            return applyInitSearch(state);
        case actions.INIT_DATA:
            return applyInitData(state);

        case actions.CHANGE_VALUE:
            return applyChangeValue(state, action);

        case actions.CHANGE_VALUE_SEARCH_EDGEBOX:
            return applyChangeSearchEdgebox(state, action);
        case actions.CHANGE_VALUE_SEARCH_SMARTWATCH:
            return applyChangeSearchSmartwatch(state, action);
        case actions.CHANGE_VALUE_SEARCH_KIOSK:
            return applyChangeSearchKiosk(state, action);

        case actions.CHANGE_VALUE_DEVICE_INFO_EDGEBOX:
            return applyChangeDeviceInfoEdgebox(state, action);
        case actions.CHANGE_VALUE_DEVICE_INFO_SMARTWATCH:
            return applyChangeDeviceInfoSmartwatch(state, action);
        case actions.CHANGE_VALUE_DEVICE_INFO_KIOSK:
            return applyChangeDeviceInfoKiosk(state, action);

        case actions.FETCH_LIST_SUCCESS_EDGEBOX:
            return applyFetchListSuccessEdgebox(state, action);
        case actions.FETCH_LIST_SUCCESS_SMARTWATCH:
            return applyFetchListSuccessSmartwatch(state, action);
        case actions.FETCH_LIST_SUCCESS_KIOSK:
            return applyFetchListSuccessKiosk(state, action);

        default:
            return state;
    }
}

/**
 * Define Reducer Pure function
 */
function applyInitSearch(state) {
    return state
        .setIn(edgeboxSearch.deviceId, '')
        .setIn(smartwatchSearch.deviceId, '')
        .setIn(kioskSearch.deviceId, '')
}
function applyInitData(state) {
    return state
        .setIn(edgeboxInfo.tableVisible, false)
        .setIn(edgeboxInfo.loading, false)
        .setIn(smartwatchInfo.tableVisible, false)
        .setIn(smartwatchInfo.loading, false)
        .setIn(kioskInfo.tableVisible, false)
        .setIn(kioskInfo.loading, false)
}

function applyChangeValue(state, action) {
    return state.set(action.prop, action.value)
}

function applyChangeSearchEdgebox(state, action) {
    return state.setIn(edgeboxSearch[action.prop], action.value)
}
function applyChangeSearchSmartwatch(state, action) {
    return state.setIn(smartwatchSearch[action.prop], action.value)
}
function applyChangeSearchKiosk(state, action) {
    return state.setIn(kioskSearch[action.prop], action.value)
}
function applyChangeDeviceInfoEdgebox(state, action) {
    return state.setIn(edgeboxInfo[action.prop], action.value)
}
function applyChangeDeviceInfoSmartwatch(state, action) {
    return state.setIn(smartwatchInfo[action.prop], action.value)
}
function applyChangeDeviceInfoKiosk(state, action) {
    return state.setIn(kioskInfo[action.prop], action.value)
}

function applyFetchListSuccessEdgebox(state, action) {
    return state.setIn(edgeboxInfo.list, action.payload)
}
function applyFetchListSuccessSmartwatch(state, action) {
    return state.setIn(smartwatchInfo.list, action.payload)
}
function applyFetchListSuccessKiosk(state, action) {
    return state.setIn(kioskInfo.list, action.payload)
}


/**
 * Define Initial Edit State
 */

const initEditForm = Map({
    code: '',
    name: '',
});

const initEditState = Map({
    editForm: Map().merge(initEditForm),

});

// const editForm = convertNestedByArray(initEditState.get('editForm').toJS(), 'editForm');

/**
 * Define Edit Reducer
 *
 * @param state
 * @param action
 * @returns {*}
 */

export function editReducer(state = initEditState, action) {
    switch (action.type) {

        // case actions.INIT_CREATE:
        //     return applyInitCreate(state);

        default:
            return state;
    }
}

//editReducer
// function applyInitCreate(state) {
//     return state.set('editForm', Map().merge(initEditForm)).set('file', '')
// }


export default combineReducers({
    index: indexReducer,
    edit: editReducer,
    edgeboxPager: pagerReducerFor('EDGEBOX'),
    smartwatchPager: pagerReducerFor('SMARTWATCH'),
    kioskPager: pagerReducerFor('KIOSK'),
});