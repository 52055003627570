import { combineReducers } from 'redux';
import { Map, List } from 'immutable';

import actions  from './actions';

/**
 * Define Initial Index State
 */
const initIndexState = Map({
	list: List(),
});

/**
 * Define Index Reducer
 *
 * @param state
 * @param action
 * @returns {*}
 */
export function indexReducer(state = initIndexState, action) {
	switch (action.type) {

		case actions.INIT:
			return applyInitialize(state, action);

		case actions.LOUNGE_SUCCESS:
			return applyloungeSuccess(state, action);

		default:
			return state;
	}
}

function applyInitialize(state, action) {
	return state.set('list', List());
}

// Search List
function applyloungeSuccess(state, action) {
	return state.set('list', List().merge(action.payload));
}

export default combineReducers({
	index: indexReducer,
});

