const getParent = (lastRoute) => {
	const parent = [];
	if (!lastRoute) return parent;
	parent.push(lastRoute);

	return parent;
};

export function getDefaultPath() {
	if (window && window.location.pathname) {
		const routes = window.location.pathname.replace(/\/$/,'').split('/');
		if (routes.length > 1) {
		    if (routes.length === 3) { // "/common/workspace"
		        routes.push('');
		    }
			//const lastRoute = routes[routes.length - 1];
			let lastRoute = routes.pop();
			if (/.+@.+/.exec(lastRoute) || /.{8}-.{4}-.{4}-.{4}-.{12}/.exec(lastRoute)) {
			    lastRoute = routes.pop();
			}
			return getParent(lastRoute);
		}
	}
	return [];
}
