export function getView(width) {
	let newView = 'MobileView';
	if (width > 1220) {
		newView = 'DesktopView';
	} else if (width > 767) {
		newView = 'TabView';
	}

	return newView;
}

/**
 * Define Action Type & Action Creator
 */
const scope = 'DATASTORE';
const actions = {
	//
	CATEGORY_LIST         : `${scope}_CATEGORY_LIST`,
	CATEGORY_LIST_SUCCESS : `${scope}_CATEGORY_LIST_SUCCESS`,

	GET_GURMWI_META         : `${scope}_GET_GURMWI_META`,
	GET_GURMWI_META_SUCCESS : `${scope}_GET_GURMWI_META_SUCCESS`,

	COLLPSE_CHANGE      : `${scope}_COLLPSE_CHANGE`,
	COLLPSE_OPEN_DRAWER : `${scope}_COLLPSE_OPEN_DRAWER`,

	TOGGLE_ALL : `${scope}_TOGGLE_ALL`,
	CLOSE_ALL  : `${scope}_CLOSE_ALL`,

	CHANGE_OPEN_KEYS : `${scope}_CHANGE_OPEN_KEYS`,
	CHANGE_CURRENT   : `${scope}_CHANGE_CURRENT`,

	GOTO_APP_STORE : `${scope}_GOTO_APP_STORE`,

	ADD_DEFERRED_TASKS: `${scope}_ADD_DEFERRED_TASKS`,
	EMPTY_DEFERRED_TASKS: `${scope}_EMPTY_DEFERRED_TASKS`,

	FETCH_MEMBERSHIP: `${scope}_FETCH_MEMBERSHIP`,
	FETCH_MEMBERSHIP_SUCCESS: `${scope}_FETCH_MEMBERSHIP_SUCCESS`,
	//파일 타입 조회
	FIND_FILE_TYPE					: `${scope}_FIND_FILE_TYPE`,

	/** 파일 타입 조회 **/
	findFileType: () => ({
		type: actions.FIND_FILE_TYPE,
	}),

	categoryList: () => ({
		type: actions.CATEGORY_LIST,
	}),

	categoryListSuccess: (payload) => ({
		type: actions.CATEGORY_LIST_SUCCESS,
		payload,
	}),

	getGurmwiMeta: (callback) => ({
		type: actions.GET_GURMWI_META,
		callback,
	}),

	getGurmwiMetaSuccess: (payload) => ({
		type: actions.GET_GURMWI_META_SUCCESS,
		payload,
	}),

	toggleCollapsed: () => ({
		type: actions.COLLPSE_CHANGE,
	}),

	toggleAll: (width, height) => {
		const view = getView(width);
		const collapsed = view !== 'DesktopView';
		return {
			type: actions.TOGGLE_ALL,
			collapsed,
			view,
			height,
		};
	},

	toggleOpenDrawer: () => ({
		type: actions.COLLPSE_OPEN_DRAWER,
	}),

	changeOpenKeys: (openKeys) => ({
		type: actions.CHANGE_OPEN_KEYS,
		openKeys,
	}),

	changeCurrent: (current) => ({
		type: actions.CHANGE_CURRENT,
		current,
	}),

	closeAll: () => ({
		type: actions.CLOSE_ALL,
	}),

	gotoAppStore: () => ({
		type: actions.GOTO_APP_STORE,
	}),

    addDeferredTasks: (tasks) => ({
        type: actions.ADD_DEFERRED_TASKS,
        tasks,
    }),
    emptyDeferredTasks: () => ({
        type: actions.EMPTY_DEFERRED_TASKS,
    }),

	fetchMembership: (callback) => ({
		type: actions.FETCH_MEMBERSHIP,
		callback,
	}),

	fetchMembershipSuccess: (payload) => ({
		type: actions.FETCH_MEMBERSHIP_SUCCESS,
		payload,
	}),

};

export default actions;
export {
	scope
}