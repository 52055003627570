import {combineReducers} from "redux";
import {Map, List} from "immutable";

import actions, {scope} from './actions';
import {convertNestedByArray} from "../../helpers/utility";
import pagerReducerFor from "../common/pagination/reducerFor";

/**
 * Set Initial State
 */

const initSearch = Map({
    name: '',
    device: '',
    date: '',
});
const initIndexState = Map({
    search: Map().merge(initSearch),
    list: List(),
});

const search = convertNestedByArray(initIndexState.get('search').toJS(), 'search');

/**
 * Define Reducer
 */
export function indexReducer(state = initIndexState, action) {
    switch (action.type) {
        //
        case actions.INIT_SEARCH:
            return applyInitSearch(state);

        case actions.INIT_SEARCH_SELECT:
            return applyInitSearchSelectValue(state);

        case actions.FETCH_LIST_SUCCESS:
            return applyFetchListSuccess(state, action);

        case actions.CHANGE_SEARCH_VALUE:
            return applyChangeValue(state, action);
        case actions.CHANGE_SEARCH_DATE_RANGE_VALUE:
            return applyChangeDateRangeValue(state, action);
        default:
            return state;
    }
}

/**
 * Define Reducer Pure function
 */
function applyInitSearch(state) {
    return state.set('search', Map().merge(initSearch));
}
function applyInitSearchSelectValue(state) {
    return state.setIn(search.device, '');
}
function applyFetchListSuccess(state, action) {
    return state.set('list', List().merge(action.payload));
}

function applyChangeValue(state, action) {
    return state.setIn(search[action.prop], action.value)
}
function applyChangeDateRangeValue(state, action) {
    let dateValue = action.value[0] + ":" + action.value[1]
    return state.setIn(search[action.prop], dateValue)
}

/**
 * Define Initial Edit State
 */

const initEditForm = Map({
    code: '',
    name: '',
});

const initEditState = Map({
    editForm: Map().merge(initEditForm),

});

// const editForm = convertNestedByArray(initEditState.get('editForm').toJS(), 'editForm');

/**
 * Define Edit Reducer
 *
 * @param state
 * @param action
 * @returns {*}
 */

export function editReducer(state = initEditState, action) {
    switch (action.type) {

        // case actions.INIT_CREATE:
        //     return applyInitCreate(state);

        default:
            return state;
    }
}

//editReducer
// function applyInitCreate(state) {
//     return state.set('editForm', Map().merge(initEditForm)).set('file', '')
// }


export default combineReducers({
    index: indexReducer,
    edit: editReducer,
    indexPager: pagerReducerFor(scope),
    sitePager: pagerReducerFor('DEVICE_STATUS'),
});