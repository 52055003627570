import { createSelector } from 'reselect';

const indexSelector = {
	form: createSelector(
		state => state.app.index.get('form'),
		form => ({
			collapsed: form.get('collapsed'),
			view: form.get('view'),
			height: form.get('height'),
			openDrawer: form.get('openDrawer'),
			openKeys: form.get('openKeys'),
			current: form.get('current'),
			gurmwimeta: form.get('gurmwimeta'),
			deferredTasks: form.get('deferredTasks'),
		}),
	),
	menulist: createSelector(
		state => state.app.index.get('menulist'),
		menulist => (
			menulist.map((item, index) => {
				item.key = item.id;
				return item;
			}).toJS()
		),
	),
	site: createSelector(
		state => state.app.index.get('site'),
		site => ({
			siteId: site.get('siteId'),
			siteCode: site.get('siteCode'),
			siteName: site.get('siteName'),
			siteAuthority: site.get('siteAuthority'),
			positionName: site.get('positionName'),
		}),
	),
};

export {
	indexSelector,
};