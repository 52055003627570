import axios, {collectContext} from '../../settings/serviceConfig';
import {getParams} from "../../helpers/utility";

// FIXME restapi 생성되면 작업
const fetchListEdgebox = (conditions) => {
  const param = getParams(conditions)
  return axios.get(`${collectContext}/dashboard/edgeBox/list` + param);
};
const fetchListSmartwatch = (conditions) => {
  const param = getParams(conditions)
  return axios.get(`${collectContext}/dashboard/smartWatch/list` + param);
};
const fetchListKiosk = (conditions) => {
  const param = getParams(conditions)
  return axios.get(`${collectContext}/dashboard/kiosk/list` + param);
};

export {
  fetchListEdgebox,
  fetchListSmartwatch,
  fetchListKiosk,
};