import {combineReducers} from "redux";
import {List, Map} from "immutable";

import actions, {scope} from './actions';
import pagerReducerFor from "../common/pagination/reducerFor";
import {convertNestedByArray} from "../../helpers/utility";

/**
 * Set Initial State
 */

const initSearch = Map({
    deviceId: '',
    deviceCode: '',
});

const initIndexState = Map({
    search: Map().merge(initSearch),
    list: List(),
    deviceModalVisible: false,
});

const search = convertNestedByArray(initIndexState.get('search').toJS(), 'search');

/**
 * Define Reducer
 */
export function indexReducer(state = initIndexState, action) {
    switch (action.type) {
        //
        case actions.INIT_SEARCH:
            return applyInitSearch(state);
        case actions.INIT_DATA:
            return applyInitData(state);

        case actions.CHANGE_VALUE:
            return applyChangeValue(state, action);
        case actions.CHANGE_SEARCH_VALUE:
            return applyChangeSearchValue(state, action);

        case actions.FETCH_MODAL_SUCCESS:
            return applyFetchModalSuccess(state, action);

        default:
            return state;
    }
}

/**
 * Define Reducer Pure function
 */
function applyInitData(state) {
    return state.set('deviceModalVisible', false)
        .set('list', List())
}
function applyInitSearch(state) {
    return state.set('search', Map().merge(initSearch))
}

function applyChangeValue(state, action) {
    return state.set(action.prop, action.value)
}
function applyChangeSearchValue(state, action) {
    return state.setIn(search[action.prop], action.value)
}

function applyFetchModalSuccess(state, action) {
    return state.set('list', List().merge(action.payload))
}


/**
 * Define Initial Edit State
 */

const initEditForm = Map({
    code: '',
    name: '',
});

const initEditState = Map({
    editForm: Map().merge(initEditForm),

});

// const editForm = convertNestedByArray(initEditState.get('editForm').toJS(), 'editForm');

/**
 * Define Edit Reducer
 *
 * @param state
 * @param action
 * @returns {*}
 */

export function editReducer(state = initEditState, action) {
    switch (action.type) {

        // case actions.INIT_CREATE:
        //     return applyInitCreate(state);

        default:
            return state;
    }
}

//editReducer
// function applyInitCreate(state) {
//     return state.set('editForm', Map().merge(initEditForm)).set('file', '')
// }


export default combineReducers({
    index: indexReducer,
    edit: editReducer,
    indexPager: pagerReducerFor(scope),
});