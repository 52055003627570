// prettier-ignore
import styled from 'styled-components';
import { palette } from 'styled-theme';

const CommonStyleHolder = styled.div`
  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 16px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .trigger:hover {
    color: ${palette('primary', 0)};
  }

  .ant-layout-sider-collapsed .anticon {
    font-size: 16px;
  }

  .ant-layout-sider-collapsed .nav-text {
    display: none;
  }

  .ant-layout {
    background: ${palette('secondary', 1)};

    &.teoContentMainLayout {
      overflow: auto;
      overflow-x: hidden;
      @media only screen and (min-width: 768px) and (max-width: 1220px) {
        width: calc(100% - 80px);
        flex-shrink: 0;
      }

      @media only screen and (max-width: 767px) {
        width: 100%;
        flex-shrink: 0;
      }
    }
  }

  .teoLayoutContent {
    width: 100%;
    padding: 35px;
    background-color: #ffffff;
    border: 1px solid ${palette('border', 0)};
    height: 100%;
  }

  .teoLayout {
    width: calc(100% - 240px);
    flex-shrink: 0;
    overflow-x: hidden !important;

    @media only screen and (max-width: 767px) {
      width: 100%;
    }

    @media only screen and (min-width: 768px) and (max-width: 1220px) {
      width: calc(100% - 80px);
    }
  }

  .ant-layout-footer {
    font-size: 13px;
    padding: 12px 36px;
    @media (max-width: 767px) {
      padding: 10px 20px;
    }
  }

  .h-button-group button {
    margin-right: 5px;
    &:last-of-type {
      margin-right: 0;
    }
  }

  /** Ant Design Override **/

  .ant-table-middle > .ant-table-title,
  .ant-table-middle > .ant-table-footer {
    padding: 5px 5px;
  }
  .ant-table-middle > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
  .ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
  .ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
  .ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
  .ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
  .ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
  .ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
  .ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
  .ant-table-middle > .ant-table-content > .ant-table-header > table > .ant-table-tbody > tr > td,
  .ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
  .ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-tbody > tr > td,
  .ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td,
  .ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-tbody > tr > td,
  .ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-tbody > tr > td,
  .ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td,
  .ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td {
    padding: 5px 5px;
  }

  .ant-table-small > .ant-table-title,
  .ant-table-small > .ant-table-footer {
    padding: 4px 4px;
  }

  .ant-table-small > .ant-table-content > .ant-table-header > table,
  .ant-table-small > .ant-table-content > .ant-table-body > table,
  .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table,
  .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table,
  .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table,
  .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table,
  .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table,
  .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table {
    border: 0;
    padding: 0 4px;
  }
  .ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
  .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
  .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
  .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
  .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
  .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
  .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
  .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
  .ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-tbody > tr > td,
  .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
  .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-tbody > tr > td,
  .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td,
  .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-tbody > tr > td,
  .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-tbody > tr > td,
  .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td,
  .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td {
    padding: 4px 4px;
  }

  .ant-calendar-picker {
    padding-bottom: 1px;
    display: block;
  }

  .ant-btn {
    padding: 0 10px;
  }

  .ant-btn > .anticon + span,
  .ant-btn > span + .anticon {
    margin-left: 5px;
  }
  
  table .dot {
    display: inline-block;
    margin-right: 6px;
    width: 8px;
    height: 8px;
    border-radius: 4px;
  }
  table .dot.green {
    background: #00c73c;
  }
  table .dot.red {
    background: #f44336;
  }
  table .dot.blue {
    background: #2196F5;
  }
  table .dot.yellow {
    background: #eab416;
  }
  table .dot.black {
    background: #2a2b3c;
  }
`;

export default CommonStyleHolder;
