import { Input as Ipt } from 'antd';
import {
  InputStyle,
  TextAreaStyle,
} from './input.style';
import WithDirection from '../../../../settings/withDirection';

const { TextArea, Search } = Ipt;

const WDStyledInput = InputStyle(Ipt);
const Input = WithDirection(WDStyledInput);

const WDTextarea = TextAreaStyle(TextArea);
const Textarea = WithDirection(WDTextarea);

const WDTPassword = InputStyle(Ipt.Password);
const Password = WithDirection(WDTPassword);

const WDTSearch = InputStyle(Search);
const BtnInput = WithDirection(WDTSearch);

export default Input;
export { Textarea, Password, BtnInput };
