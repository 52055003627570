import { Select as Combo } from 'antd';
import { SelectStyle } from './select.style';
import WithDirection from '../../../../settings/withDirection';

const WDAntSelect = SelectStyle(Combo);
const Select = WithDirection(WDAntSelect);
const AntSelectOption = Combo.Option;
const SelectOption = AntSelectOption;

export default Select;
export { SelectOption };
