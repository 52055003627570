import { createSelector } from 'reselect';

const indexSelector = {
	list: createSelector(
		state => state.dashboard.index.get('list'),
		list => list.map((item, index) => {
			item.key = item.id;
			return item;
		}).toJS(),
	),
};

export {
	indexSelector,
};
