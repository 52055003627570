import React from 'react';
import { Form as Frm } from 'antd';
import { FormStyle } from './form.style';
// import WithDirection from '../../../../settings/withDirection';

const Form = FormStyle(Frm);
// const Form = WithDirection(AntForm);
const AntFormItem = Frm.Item;
const FormItem = (props) => (<AntFormItem colon={false} {...props}>{props.children}</AntFormItem>);

export default Form;
export { FormItem };
