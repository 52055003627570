import axio from 'axios';
import {getToken, getRefreshToken, setToken} from 'gurmwi-share-front/share/helpers/utility';

/**
 * Axios Global Configuration Setting
 */
const axiosInstance = axio.create({
	baseURL: window.gurmwi.nest.API_HOST,
	headers: {
		'Access-Control-Allow-Origin': '*',
		'Content-Type': 'application/json',
		'Content-Security-Policy' : 'upgrade-insecure-requests',
	}
});

const setHeaders = () => {
	const token = getToken();
	if (token) {
		axiosInstance.defaults.headers.common['Authorization'] = `${token}`;
	} else {
		delete axiosInstance.defaults.headers.common['Authorization'];
	}
	const refreshToken = getRefreshToken();
	if (refreshToken) {
		axiosInstance.defaults.headers.common['Refresh_Token'] = `${refreshToken}`;
	} else {
		delete axiosInstance.defaults.headers.common['Refresh_Token'];
	}
};

// Request Interceptor
axiosInstance.interceptors.request.use(
	(config) => {
		// console.log('[intercept req]', config);
		// store.dispatch('showLoading');
		return config;
	},
	(error) => {
		// console.log('[intercept req error]', error);
		// store.dispatch('hideLoading);
		return Promise.reject(error);
	},
);

// Response Interceptor
axiosInstance.interceptors.response.use(
	(response) => {
		// console.log('[intercept res]', response);
		// store.dispatch('hideLoading);
		if (response.headers['reissue_token']) {
		    //console.log('axios response interceptor', 'reissue_token=', response.headers['reissue_token']);
		    //console.log('axios response interceptor', 'reissue_refresh_token=', response.headers['reissue_refresh_token']);
		    let token = response.headers['reissue_token'];
		    let refreshToken = response.headers['reissue_refresh_token'];
		    setToken(token, refreshToken);
		    setHeaders();
		}
		return response;
	},
	(error) => {
		// console.log('[intercept req error]', error);
		// store.dispatch('hideLoading);
		return Promise.reject(error);
	},
);

const context = (window.gurmwi.nest.USE_APP_CONTEXT) ? `/dashboard` : '';
const collectContext = (window.gurmwi.nest.USE_APP_CONTEXT) ? `/collect-${window.gurmwi.nest.WORKSPACE}` : '';

const loginUrl = `${window.gurmwi.nest.API_HOST}/dashboard/gurmwi/login`;
const tokenUrl = `${window.gurmwi.nest.API_HOST}/dashboard`;

export default axiosInstance;
export {
	setHeaders,

	context,
	collectContext,

	loginUrl,
	tokenUrl,
}