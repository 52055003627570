import axios, {collectContext}  from '../../settings/serviceConfig';
import {getParams} from "../../helpers/utility";

const fetchModal = (conditions) => {
  const param = getParams(conditions)
  return axios.get(`${collectContext}/workerDashBoard/deviceSearch` + param);
};

export {
  fetchModal,
};