export default {
	apiUrl: window.gurmwi.nest.API_HOST,
	// apiUrl: "http://118.67.135.191:8080",
};

const siteConfig = {
	siteName: 'ephs',
	siteIcon: 'ion-flash',
	siteTitle: 'ephs',
	footerText: 'COPYRIGHT 2019 GURMWI corp. ALL RIGHTS RESERVED.',
};
const themeConfig = {
	topbar: 'themedefault',
	sidebar: 'themedefault',
	layout: 'themedefault',
	theme: 'themedefault',
};
const appRootPath = '';
const rootPath = '';
export {
	siteConfig,
	themeConfig,
	appRootPath,
	rootPath,
};
