import {push} from 'react-router-redux';
import {call, put, spawn, takeEvery} from 'redux-saga/effects';
import {spinnerActions} from 'gurmwi-share-front/share/spinner';
import {errorMessage} from 'gurmwi-share-front/share/feedback/Message/message';

import actions from './actions';
import * as service from './serviceApi';
import {appRootPath} from '../../settings';
import {setType} from "../../helpers/utility";

export function* getCateCodeListSaga() {
  yield put(spinnerActions.start());
  try {
    const response = yield call(service.categoryList);
    yield put(actions.categoryListSuccess(response.data));
    yield put(spinnerActions.success());
  } catch (error) {
    yield put(spinnerActions.failure());
    errorMessage('카테고리 목록 조회');
  }
}

export function* getGurmwimetaSaga(action) {
  yield put(spinnerActions.start());
  try {
    const response = yield call(service.getGurmwimeta);
    yield put(actions.getGurmwiMetaSuccess(response.data));
    if (typeof action.callback === 'function') {
      yield action.callback(response.data);
    }
    yield put(spinnerActions.success());
  } catch (error) {
    yield put(spinnerActions.failure());
    errorMessage('구름위 메타 정보');
  }
}

export function* gotoAppStoreSaga() {
  //FIXME 기본 게시판?
  yield put(push(`${appRootPath}`));
  yield put(actions.changeCurrent(['']));
}

export function* fetchMembershipSaga(action) {
  yield put(spinnerActions.start());
  try {
    const response = yield call(service.fetchMembership);
    if(response.data.sites)
    yield put(actions.fetchMembershipSuccess(response.data.sites[0]));
    yield put(spinnerActions.success());
    if (typeof action.callback == "function") {
      action.callback();
    }
  } catch (error) {
    yield put(spinnerActions.failure());
    errorMessage('사이트 조회');
  }
}

export function* gotoOpenApiSaga() {
  yield put(push(`${appRootPath}/`));
}
//파일 타입조회
export function* findFileTypeSaga() {
  yield put(spinnerActions.start());
  try {
    const response = yield call(service.findFileType);
    if (response.data) {
      setType(response.data);
      yield put(spinnerActions.success());
    }
  } catch (error) {
    yield put(spinnerActions.failure());
    errorMessage('파일타입 조회');
  }
}

export function* watchSaga() {
  yield takeEvery(actions.CATEGORY_LIST, getCateCodeListSaga);
  yield takeEvery(actions.GET_GURMWI_META, getGurmwimetaSaga);
  yield takeEvery(actions.GOTO_APP_STORE, gotoAppStoreSaga);
  yield takeEvery(actions.FETCH_MEMBERSHIP, fetchMembershipSaga);
  yield takeEvery(actions.FIND_FILE_TYPE, findFileTypeSaga);
}

export default function* rootSaga() {
  yield spawn(watchSaga);
}
