import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {getToken} from 'gurmwi-share-front/share/helpers/utility';

import appActions from '../../../store/app/actions';
import authActions from '../../../store/auth/actions';
import dashboardActions from '../../../store/dashboard/actions';
import {indexSelector as authIndexSelector} from "../../../store/auth/selectors";
import {Popover} from '../../../components/common/ant-ui/index';
import userpic from '../../../image/login-user.png';
import TopbarDropdownWrapper from './topbarDropdown.style';
import {indexSelector as appSelector} from "../../../store/app/selectors";
import {loginUrl} from "../../../settings/serviceConfig";

class TopbarUser extends Component {
  //
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      // open: false,
    };
  }

  logoutActions = async () => {
    await this.hide();
    // const { virtualHome } = this.props.appForm.gurmwimeta;
    // const frontGatewayUrl = virtualHome && virtualHome.frontGatewayUrl;
    // await this.props.tokenActions.logout(frontGatewayUrl);
    await this.props.authActions.logout('');
    // await this.props.appActions.gotoMyLobby();
    // await this.props.dashboardActions.initialize();
    await this.props.appActions.closeAll();
  };

  loginActions = () => {
    this.hide();
    this.props.appActions.closeAll();
  };

  content = () => {
    const token = getToken();
    const { userId, userName } = this.props.authForm;
    const {goProfile} = this.props;

    return (
      <TopbarDropdownWrapper className="teoUserDropdown">
        <div className="teoDropdownHeader">
          {
            userId ?
              <h3>{userName}</h3>
              :
              <h3>로그인 후 사용가능합니다.</h3>
          }

        </div>
        <div className="teoDropdownBody height-auto">
          {
            userName && token ?
              <div>
                <div
                  className="teoDropdownListItem"
                  onClick={() => goProfile(this.hide())}
                >
                  {<h5>내 정보</h5>}
                </div>
                <div
                  className="teoDropdownListItem"
                  onClick={() => this.logoutActions()}
                >
                  {<h5>Logout</h5>}
                </div>
              </div>
              :
              <a href={loginUrl}>
                <div
                  className="teoDropdownListItem"
                  onClick={() => this.loginActions()}
                >
                  {<h5>Login</h5>}
                </div>
              </a>
          }
        </div>
      </TopbarDropdownWrapper>
    )
  };

  hide = () => {
    this.setState({
      visible: false
      // open: false
    });
  };
  handleVisibleChange = () => {
    this.setState({
      visible: !this.state.visible
      // open: !this.state.open
    });
  };

  render() {
    //
    return (
      <Popover
        content={this.content()}
        trigger="click"
        open={this.state.visible}
        onOpenChange={this.handleVisibleChange}
        arrowPointAtCenter={true}
        placement="bottomLeft"
      >
        <div className="teoImgWrapper">
          <img alt="user" src={userpic}/>
          <span className="userActivity online"/>
        </div>
      </Popover>
    );
  }
}

const stateToProps = (state) => ({
  authForm: authIndexSelector.form(state),
  appForm: appSelector.form(state),
});

const dispatchToProps = (dispatch) => ({
  authActions: bindActionCreators(authActions, dispatch),
  appActions: bindActionCreators(appActions, dispatch),
  dashboardActions: bindActionCreators(dashboardActions, dispatch),
});

export default connect(
  stateToProps,
  dispatchToProps,
)(TopbarUser);
