import {profileReducers as profile,} from 'site-ui-module';

import app from './app/reducers';
import auth from './auth/reducers';
import dashboard from './dashboard/reducers';

import deviceStatus from './deviceStatus/reducers';
import workplaceStatus from './workplaceDeviceStatus/reducers';
import deviceSearch from './deviceSearch/reducers';
import worker from './worker/reducers';
import edgebox from './edgebox/reducers';

export default {
	profile,
	app,
	auth,
	dashboard,

	deviceStatus,
	workplaceStatus,
	deviceSearch,
	worker,
	edgebox,
};