/**
 * define action types
 * @type {{REQUEST_START: string, REQUEST_SUCCESS: string, REQUEST_FAILURE: string}}
 */
export const actionTypes = {
  REQUEST_START: 'SPINNER_REQUEST_START',
  REQUEST_SUCCESS: 'SPINNER_REQUEST_SUCCESS',
  REQUEST_FAILURE: 'SPINNER_REQUEST_FAILURE',
};

/**
 * action creator
 * @param type
 * @returns {function(): {type: *}}
 */
const actionCreator = type => () => ({ type });

export const spinnerActions = {
  start: actionCreator(actionTypes.REQUEST_START),
  success: actionCreator(actionTypes.REQUEST_SUCCESS),
  failure: actionCreator(actionTypes.REQUEST_FAILURE),
};
