import {call, put, spawn, takeEvery} from 'redux-saga/effects';
import {spinnerActions} from 'gurmwi-share-front/share/spinner';
// import {infoMessage} from 'gurmwi-share-front/share/feedback/Message/message';
// import {errorModal} from 'gurmwi-share-front/share/feedback/Modal/modal';
import actions from './actions';
import * as service from './serviceApi';
import {createPagerPayload} from "../../helpers/utility";
// import {push} from "react-router-redux";
// import {appRootPath} from "../../settings";

export function* fetchListSaga(action) {
    yield put(spinnerActions.start());
    try {
        const response = yield call(service.fetchList, action.conditions);
        yield put(actions.fetchListSuccess(response.data));
        if (response.data.length < 1) {
            // infoMessage('데이터가 존재하지 않습니다.');
        }
        yield put(actions.setLoading('loading', false));
        yield put(spinnerActions.success());
    } catch (error) {
        yield put(actions.setLoading('loading', false));
        yield put(spinnerActions.failure());
        // errorModal('목록 조회', error);
    }
}

export function* fetchListMainSaga(action) {
    yield put(spinnerActions.start());
    try {
        const response = yield call(service.fetchListMain, action.conditions);
        yield put(actions.fetchListMainSuccess(response.data));
        if (response.data.length < 1) {
            // infoMessage('데이터가 존재하지 않습니다.');
        }
        yield put(actions.setLoading('w_loading', false));
        yield put(spinnerActions.success());
    } catch (error) {
        yield put(actions.setLoading('w_loading', false));
        yield put(spinnerActions.failure());
        // errorModal('목록 조회', error);
    }
}

export function* fetchListKioskSaga(action) {
    yield put(spinnerActions.start());
    try {
        const response = yield call(service.fetchListKiosk, action.conditions);
        yield put(actions.setListPagerModal(createPagerPayload(response.data)));
        yield put(actions.fetchListKioskSuccess(response.data.items));
        if (response.data.length < 1) {
            // infoMessage('데이터가 존재하지 않습니다.');
        }
        yield put(actions.setLoading('m_loading', false));
        yield put(spinnerActions.success());
    } catch (error) {
        yield put(actions.setLoading('m_loading', false));
        yield put(spinnerActions.failure());
        // errorModal('목록 조회', error);
    }
}

export function* fetchListSmartWatchSaga(action) {
    yield put(spinnerActions.start());
    try {
        const response = yield call(service.fetchListSmartWatch, action.conditions);
        yield put(actions.setListPagerModal(createPagerPayload(response.data)));
        // 리듀서에 smartwatch 데이터 저장하는 action 호출
        yield put(actions.fetchListSmartWatchSuccess(response.data.items));
        if (response.data.length < 1) {
            // infoMessage('데이터가 존재하지 않습니다.');
        }
        yield put(actions.setLoading('m_loading', false));
        yield put(spinnerActions.success());
    } catch (error) {
        yield put(actions.setListPagerModal({
            page: 0,
            pageSize: 10,
            totalPages: 0,
            totalItemCount: 0,
        }));
        yield put(actions.setLoading('m_loading', false));
        yield put(spinnerActions.failure());
        // errorModal('목록 조회', error);
    }
}

/**
 * 스마트방석
 * @param action
 * @returns {Generator<*, void, *>}
 */
export function* fetchListSmartChairSaga(action) {
    yield put(spinnerActions.start());
    try {
        const response = yield call(service.fetchListSmartChair, action.conditions);
        if (response.data.length < 1) {
            // infoMessage('데이터가 존재하지 않습니다.');
        }
        yield put(actions.setListPagerModal(createPagerPayload(response.data)));
        yield put(actions.fetchListSmartChairSuccess(response.data.currentData));
        yield put(actions.setLoading('m_loading', false));
        yield put(spinnerActions.success());
    } catch (error) {
        yield put(actions.setListPagerModal({
            page: 0,
            pageSize: 10,
            totalPages: 0,
            totalItemCount: 0,
        }));
        yield put(actions.setLoading('m_loading', false));
        yield put(spinnerActions.failure());
        // errorModal('목록 조회', error);
    }
}

export function* fetchListEdgeBoxSaga(action) {
    yield put(spinnerActions.start());
    try {
        const response = yield call(service.fetchListEdgeBox, action.conditions);
        yield put(actions.fetchListEdgeboxSuccess(response.data));
        if (response.data.length < 1) {
            // infoMessage('데이터가 존재하지 않습니다.');
        }
        yield put(actions.setLoading('e_loading', false));
        yield put(spinnerActions.success());
    } catch (error) {
        yield put(actions.setLoading('e_loading', false));
        yield put(spinnerActions.failure());
        // errorModal('목록 조회', error);
    }
}

export function* fetchListEdgeBoxModalSaga(action) {
    yield put(spinnerActions.start());
    try {
        const response = yield call(service.fetchListEdgeBoxModal, action.conditions);
        yield put(actions.setListPagerModal(createPagerPayload(response.data)));
        yield put(actions.fetchListEdgeboxModalSuccess(response.data.items));
        if (response.data.length < 1) {
            // infoMessage('데이터가 존재하지 않습니다.');
        }
        yield put(actions.setLoading('m_loading', false));
        yield put(spinnerActions.success());
    } catch (error) {
        yield put(actions.setLoading('m_loading', false));
        yield put(spinnerActions.failure());
        // errorModal('목록 조회', error);
    }
}

export function* fetchListDetailSaga(action) {
    yield put(spinnerActions.start());
    try {
        const response = yield call(service.fetchListDetail, action.conditions);
        yield put(actions.setListPager(createPagerPayload(response.data)));
        yield put(actions.fetchListDetailSuccess(response.data));
        if (response.data.length < 1) {
            // infoMessage('데이터가 존재하지 않습니다.');
        }
        yield put(actions.setLoading('d_loading', false));
        yield put(spinnerActions.success());
    } catch (error) {
        yield put(actions.setLoading('d_loading', false));
        yield put(spinnerActions.failure());
        // errorModal('목록 조회', error);
    }
}

export function* fetchWorkplaceInfoSaga(action) {
    yield put(spinnerActions.start());
    try {
        const response = yield call(service.fetchWorkplaceInfo, action.conditions);
        yield put(actions.fetchWorkplaceInfoSuccess(response.data));
        if (response.data.length < 1) {
            // infoMessage('데이터가 존재하지 않습니다.');
        }
        // yield put(actions.setLoading('d_loading', false));
        yield put(spinnerActions.success());
    } catch (error) {
        // yield put(actions.setLoading('d_loading', false));
        yield put(spinnerActions.failure());
        // errorModal('목록 조회', error);
    }
}


export function* watchSaga() {
    yield takeEvery(actions.FETCH_LIST, fetchListSaga);
    yield takeEvery(actions.FETCH_LIST_DETAIL, fetchListDetailSaga);
    yield takeEvery(actions.FETCH_LIST_MAIN, fetchListMainSaga);
    yield takeEvery(actions.FETCH_LIST_KIOSK, fetchListKioskSaga);
    // 스마트 워치 API 호출
    yield takeEvery(actions.FETCH_LIST_SMART_WATCH, fetchListSmartWatchSaga);
    // 스마트방석 API 호출
    yield takeEvery(actions.FETCH_LIST_SMART_CHAIR, fetchListSmartChairSaga);
    yield takeEvery(actions.FETCH_LIST_EDGE_BOX, fetchListEdgeBoxSaga);
    yield takeEvery(actions.FETCH_LIST_EDGE_BOX_MODAL, fetchListEdgeBoxModalSaga);
    yield takeEvery(actions.FETCH_WORKPLACE_INFO, fetchWorkplaceInfoSaga);
}

export default function* rootSaga() {
    yield spawn(watchSaga);
}