import React, {Component} from 'react';
import {connect} from 'react-redux';
import clone from 'clone';
import {Link} from 'react-router-dom';
import {Layout} from 'antd';

import Scrollbars from '../../../components/common/utility/CustomScrollBar/customScrollBar.js';
import {Menu, Icon} from '../../../components/common/ant-ui/index';
import SidebarWrapper from './sidebar.style';
import appActions from '../../../store/app/actions';
import authActions from '../../../store/auth/actions';
import dashboardActions from '../../../store/dashboard/actions';
import {indexSelector as dashboardSelector} from '../../../store/dashboard/selectors';
import {indexSelector as appSelector} from '../../../store/app/selectors';
import {indexSelector as authSelector} from '../../../store/auth/selectors';
import Logo from '../../../components/common/utility/Logo/logo';
import themes from '../../../settings/themes/index';
import {themeConfig} from '../../../settings/index';
import {bindActionCreators} from "redux";
import {stripTrailingSlash} from "../../../helpers/utility";

import menu from './menu';

const {Sider} = Layout;
const {SubMenu} = Menu;

class Sidebar extends Component {
  //
  constructor(props) {
    super(props);

    this.submenuColor = {
      color: themes[themeConfig.theme].textColor,
    };
  }

  getAncestorKeys = (key) => {
    const map = {
      sub3: ['sub2'],
    };
    return map[key] || [];
  };

  getMenuItem = ({ singleOption, submenuColor }) => {
    /*FIXME 권한*/
    const {positionAuthority} = this.props.authForm;
    const authorityType = positionAuthority && positionAuthority.authorityType;
    const {key, label, leftIcon, roles, children} = singleOption;
    const url = stripTrailingSlash(this.props.url);
    //const {virtualHome} = this.props.appForm.gurmwimeta;

    for (let i = 0, length = roles.length; i < length; i++) {
      const role = roles[i];

      if (key === 'lobby') {
        // const url = virtualHome && virtualHome.frontGatewayUrl;
        return (
            <Menu.Item key={key}>
              <a href={url}>
              <span className="teoMenuHolder" style={submenuColor}>
                {/*<i className={leftIcon} />*/}
                <Icon type={leftIcon} style={{fontSize: '14px', marginRight: '10px'}}/>
                <span className="nav-text">
                  {label}
                  {/*{virtualHome && virtualHome.workspace}-{label}*/}
                </span>
              </span>
              </a>
            </Menu.Item>
        );
      }

      // FIXME 인증 없이 로컬 개발 시 주석 해제
      // if (role === 'GUEST' || role === authorityType) {
      if (role === authorityType) {
        if (children) {
          return (
              <SubMenu
                  key={key}
                  title={
                    <span className="teoMenuHolder" style={submenuColor}>
                          <Icon type={leftIcon} style={{fontSize: '14px', marginRight: '10px'}}/>
                            <span className="nav-text">
                              {label}
                            </span>
                    </span>
                  }
              >
                {children.map((child) => {
                  // FIXME device, worker 권한 확인 후 수정
                  if(child.key.slice(-3) === ':id' && this.props.authForm.additionalInfo && this.props.authForm.additionalInfo.COMPANY){
                    return (
                        <Menu.Item style={submenuColor} key={child.key}>
                          <Link style={submenuColor} to={`${url}/${child.key.slice(0,-3)}`+this.props.authForm.additionalInfo.COMPANY }>
                            {child.label}
                          </Link>
                        </Menu.Item>
                    );
                  }
                  return (
                      <Menu.Item style={submenuColor} key={child.key}>
                        <Link style={submenuColor} to={`${url}/${child.key}`}>
                          {child.label}
                        </Link>
                      </Menu.Item>
                  );
                })}
              </SubMenu>
          )
        } else {
          return (
              <Menu.Item key={key}>
                <Link style={submenuColor} to={`${url}/${key}`}>
                  <span className="teoMenuHolder" style={submenuColor}>
                    <Icon type={leftIcon} style={{fontSize: '14px', marginRight: '10px'}}/>
                      <span className="nav-text">
                        {label}
                      </span>
                  </span>
                </Link>
              </Menu.Item>
          );
        }
      }
    }
  };

  handleMouseEnter = () => {
    if (this.props.appForm.openDrawer === false) {
      this.props.appActions.toggleOpenDrawer();
    }
  };

  handleMouseLeave = () => {
    if (this.props.appForm.openDrawer === true) {
      this.props.appActions.toggleOpenDrawer();
    }
  };

  handleClick = (event) => {
    this.props.appActions.changeCurrent([event.key]);
    if (this.props.appForm.view === 'MobileView') {
      setTimeout(() => {
        this.props.appActions.toggleCollapsed();
        this.props.appActions.toggleOpenDrawer();
      }, 100);
    }
  };

  handleOpenChange = (newOpenKeys) => {
    const {appForm} = this.props;
    const latestOpenKey = newOpenKeys.find((key) => !(appForm.openKeys.indexOf(key) > -1));
    const latestCloseKey = appForm.openKeys.find((key) => !(newOpenKeys.indexOf(key) > -1));
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = this.getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = this.getAncestorKeys(latestCloseKey);
    }
    this.props.appActions.changeOpenKeys(nextOpenKeys);
  };

  render() {
    const {appForm} = this.props;
    const {height} = appForm;
    const collapsed = clone(appForm.collapsed) && !clone(appForm.openDrawer);
    const mode = collapsed === true ? 'vertical' : 'inline';

    const customizedTheme = themes[themeConfig.theme];

    const styling = {
      backgroundColor: customizedTheme.backgroundColor,
    };
    const submenuColor = {
      color: customizedTheme.textColor,
    };
    return (
        <SidebarWrapper>
          <Sider
              trigger={null}
              collapsible={true}
              collapsed={collapsed}
              width={240}
              className="teoSidebar"
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}
              style={styling}
          >
            <Logo collapsed={collapsed}/>
            <Scrollbars style={{height: height - 60}}>
              <Menu
                  onClick={this.handleClick}
                  theme="dark"
                  className="teoDashboardMenu"
                  mode={mode}
                  openKeys={collapsed ? [] : appForm.openKeys}
                  selectedKeys={appForm.current}
                  onOpenChange={this.handleOpenChange}
              >
                {menu.map((singleOption) => this.getMenuItem({singleOption, submenuColor}))}
              </Menu>
            </Scrollbars>
          </Sider>
        </SidebarWrapper>
    );
  }
}

const stateToProps = (state) => ({
  list: dashboardSelector.list(state),

  appForm: appSelector.form(state),
  menulist: appSelector.menulist(state),

  authForm: authSelector.form(state),
});

const dispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(appActions, dispatch),
  authActions: bindActionCreators(authActions, dispatch),
  dashboardActions: bindActionCreators(dashboardActions, dispatch),
});

export default connect(
    stateToProps,
    dispatchToProps,
)(Sidebar);