import styled from 'styled-components';
import { palette, font } from 'styled-theme';

const TableStyle = (ComponentName) => styled(ComponentName)`
  .ant-table {
    font-family: ${font('primary', 0)};
    font-size: 12px;
  }
  
  .ant-table-thead > tr > th {
    background: ${palette('grayscale', 3)}
  }
  
  .ant-pagination, .ant-select, .ant-pagination-options-quick-jumper input {
    font-size: 12px;
  }
`;

export { TableStyle };
