import styled from 'styled-components';
import { font } from 'styled-theme';

const FormStyle = (ComponentName) => styled(ComponentName)`
  .ant-form {
    font-family: ${font('primary', 0)};
    font-size: 12px;
    
    label,
    output {
      font-size: 12px;
    }
  }

  .ant-form-item-required:before {
    font-size: 12px;
  }
  
  .ant-form-item {
    font-family: ${font('primary', 0)};
    font-size: 12px;
    margin-bottom: 12px;

    label, 
    label > .anticon {
      font-size: 12px;
    }
    .ant-form-vertical&{
      padding-bottom: 12px;
    }
    
    &:last-child {
      margin-bottom: 0px;
    }
  }
`;

export { FormStyle };
