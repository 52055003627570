import React from 'react';
import PropTypes from 'prop-types';
import ReduxBlockUi from 'react-block-ui/lib/redux';
import { Spin } from 'antd';
import {Icon} from '../../../components/common/ant-ui/index';
import 'react-block-ui/style.css';

import { actionTypes } from './module';

const spinnerIcon = <Icon type="loading-3-quarters" style={{ fontSize: 24 }} spin/>;

const config = {
  tag: 'div',
  block: actionTypes.REQUEST_START,
  unblock: [
    actionTypes.REQUEST_SUCCESS,
    actionTypes.REQUEST_FAILURE,
  ],
};

/**
 * BlockUI with spinner
 *
 * @param loaderType
 * @param message
 * @param children
 * @returns {*}
 * @constructor
 */
const Spinner = ({ children }) => {
  return (
    <ReduxBlockUi
      tag={config.tag}
      block={config.block}
      unblock={config.unblock}
      loader={<Spin indicator={spinnerIcon} />}
      // message="잠시만 기다려 주세요."
    >
      {children}
    </ReduxBlockUi>
  );
};

Spinner.propTypes = {
  children: PropTypes.node,
};

export default Spinner;
