const categoryList = () => {
  // return axios.get(`${context}/categories`);
};

const getGurmwimeta = () => {
  // return axios.get('http://cirrus-ephs-gateway.demo.gurmwi.io/cirrus-ephs-site/gurmwimeta');
};

// 멤버십 - 소속 사이트 조회
const fetchMembership = () => {
  // return axios.get(`${siteContext}/membership/relatedsite`);
};
// 파일타입 조회
const findFileType = () => {
  // return axios.get(`${fileContext}/file-storage/type`);
};

export {
  findFileType,
  categoryList,
  getGurmwimeta,
  fetchMembership,
};
