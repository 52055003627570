import { Tabs as Tab } from 'antd';
import TabsStyle from './tabs.style';
import WithDirection from '../../../../settings/withDirection';

const WDTabs = TabsStyle(Tab);
const Tabs = WithDirection(WDTabs);
const TabPane = Tab.TabPane;


export default Tabs;
export { TabPane };
