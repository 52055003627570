import numeral from 'numeral';

export function getType() {
  return localStorage.getItem('type');
}
export function setType(type) {
  localStorage.setItem('type',type);
}
export function clearToken() {
  localStorage.removeItem('token');
}

export function getToken() {
  return localStorage.getItem('token');
}

export function clearUserID() {
  sessionStorage.removeItem('userID');
}

export function getUserID() {
  return sessionStorage.getItem('userID');
}

export function getUrlSlug(url, offset) {
  let stripTrailSlash = stripTrailingSlash(url).split('/');
  stripTrailSlash.shift();
  let loopCount = stripTrailSlash.length - offset;

  return stripTrailSlash.reduce((accumulator, currentValue, index) => {
    if (index < loopCount) {
      return ((index === 1) ? '/' : '') + (accumulator + '/' + currentValue);
    } else {
      return accumulator;
    }
  });
}

export function getFirstUrlSlug(url) {
  let slugIndex = url.lastIndexOf('/');
  if (url.substr(slugIndex) !== '') {
    return url.substring(0, slugIndex);
  }
  return url;
}

export function getLastUrlSlug(url) {
  let slugIndex = url.lastIndexOf('/');
  return url.substr(slugIndex);
}

export function stripTrailingSlash(str) {
  if (str.substr(-1) === '/') {
    return str.substr(0, str.length - 1);
  }
  return str;
}

export function filterSelectOptionLowerCase(input, option) {
  return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
}

export function createPagerPayload(data) {
  //FIXME
  return {
    page: data.condition.page + 1,
    pageSize: data.condition.pageSize,
    totalPages: data.totalPages,
    // totalItemCount: 100,
    totalItemCount: data.totalItemCount,
  }
}

export function showTableTotalCount(total) {
  return `Total ${numeral(total).format('0,0')} items`
}

export function convertNestedByArray(sourceObject, namespace) {
  const objectWithNamspace = {};
  const keys = Object.keys(sourceObject);

  for (let i = 0, len = keys.length; i < len; i++) {
    let key = keys[i];
    if (namespace) {
      objectWithNamspace[key] = [namespace, key];
    } else {
      objectWithNamspace[key] = key;
    }
  }

  return objectWithNamspace;
}

export function getSysCodeText(codes, value) {
  if (value === null) return '';
  const codeObject = codes.find(element => (element.value === value));
  return (codeObject && codeObject.text) ? codeObject.text : 'Invalid value';
}

export function formatNumber(value) {
  return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function getJsonString(type, value, pager, statusCodes, catcd, supplyOrgId) {
  let page = pager && pager.page - 1;
  const pageSize = pager && pager.pageSize;

  if (page < 0) {
    page = 0;
  }

  const jsonString = {
    conditions: {
      values: []
    },
    page,
    pageSize,
  };

    jsonString.conditions.values.push({
      key: type,
      value: value
    });

  if (catcd && catcd.length) {
    jsonString.conditions.values.push({
      key: 'catcd',
      value: catcd
    })
  }

  if (supplyOrgId && supplyOrgId.length) {
    jsonString.conditions.values.push({
      key: 'supplyOrgId',
      value: supplyOrgId
    })
  }

  if (statusCodes && statusCodes.length) {
    jsonString.conditions.values.push({
      key: 'procStat',
      value: statusCodes
    })
  }

  return encodeURI(JSON.stringify(jsonString));
}

export function getAdminJsonString(title, name, statusCodes, pager) {
  let page = pager && pager.page - 1;
  const pageSize = pager && pager.pageSize;

  if (page < 0) {
    page = 0;
  }

  const jsonString = {
    conditions: {
      values: []
    },
    page,
    pageSize,
  };

  if (title) {
    jsonString.conditions.values.push({
      key: 'dataName',
      value: title
    })
  }
  if (name) {
    jsonString.conditions.values.push({
      key: 'supplyOrgName',
      value: name
    })
  }
  if (statusCodes && statusCodes.length) {
    jsonString.conditions.values.push({
      key: 'procStat',
      value: statusCodes
    })
  }
  return encodeURI(JSON.stringify(jsonString));
}


export function getParams(conditions) {
  const page = conditions.page;
  const pageSize = conditions.pageSize;
  const title = conditions.title;
  const status = conditions.status;
  const siteId = conditions.siteId;
  const siteName = conditions.siteName;
  const collectorId = conditions.collectorId;
  const storageType = conditions.storageType;
  const storageName = conditions.storageName;
  const collectionCode = conditions.collectionCode;
  const code = conditions.code;
  const name = conditions.name;
  const parentsId = conditions.parentsId;
  const dataSupplyGroupCode = conditions.dataSupplyGroupCode;
  const categoryCode = conditions.categoryCode;
  const categoryName = conditions.categoryName;
  const procStat = conditions.procStat;
  const dataName = conditions.dataName;
  const applicantUserId = conditions.applicantUserId;
  const categoryCodes = conditions.categoryCodes;
  const dataSupplyGroupCodes = conditions.dataSupplyGroupCodes;
  const statusCodes = conditions.statusCodes;
  const statuses = conditions.statuses;
  const procStatuses = conditions.procStatuses;
  const applicantSiteId = conditions.applicantSiteId;
  const learnDataStoreProcStatuses = conditions.learnDataStoreProcStatuses;
  const type = conditions.type;
  const koreanName = conditions.koreanName;
  const userId = conditions.userId;
  const from = conditions.from;
  const to = conditions.to;
  const workplaceId = conditions.workplaceId;
  const fromDate = conditions.fromDate;
  const toDate = conditions.toDate;
  const kioskId = conditions.kioskId;
  const smartWatchId = conditions.smartWatchId;
  const workerId = conditions.workerId;
  const edgeBoxId = conditions.edgeBoxId;
  const deviceId = conditions.deviceId;
  const machineId = conditions.machineId;
  const direction = conditions.direction;
  const startMeasureTime = conditions.startMeasureTime;
  const endMeasureTime = conditions.endMeasureTime;
  const smartChairId = conditions.smartChairId;

  let params = '?';

  if (page) params += `page=${page}`;
  if (pageSize) params += `&pageSize=${pageSize}`;
  if (title) params += `&title=${title}`;
  if (status) params += `&status=${status}`;
  if (siteId) params += `&siteId=${siteId}`;
  if (siteName) params += `&siteName=${siteName}`;
  if (collectorId) params += `&collectorId=${collectorId}`;
  if (storageType) params += `&storageType=${storageType}`;
  if (storageName) params += `&storageName=${storageName}`;
  if (collectionCode) params += `&collectionCode=${collectionCode}`;
  if (code) params += `&code=${code}`;
  if (name) params += `&name=${name}`;
  if (parentsId) params += `&parentsId=${parentsId}`;
  if (dataSupplyGroupCode) params += `&dataSupplyGroupCode=${dataSupplyGroupCode}`;
  if (categoryCode) params += `&categoryCode=${categoryCode}`;
  if (categoryName) params += `&categoryName=${categoryName}`;
  if (procStat) params += `&procStat=${procStat}`;
  if (dataName) params += `&dataName=${dataName}`;
  if (applicantUserId) params += `&applicantUserId=${applicantUserId}`;
  if (categoryCodes) params += `&categoryCodes=${categoryCodes}`;
  if (dataSupplyGroupCodes) params += `&dataSupplyGroupCodes=${dataSupplyGroupCodes}`;
  if (statusCodes) params += `&statusCodes=${statusCodes}`;
  if (statuses) params += `&statuses=${statuses}`;
  if (procStatuses) params += `&procStatuses=${procStatuses}`;
  if (applicantSiteId) params += `&applicantSiteId=${applicantSiteId}`;
  if (learnDataStoreProcStatuses) params += `&learnDataStoreProcStatuses=${learnDataStoreProcStatuses}`;
  if (type) params += `&type=${type}`;
  if (koreanName) params += `&koreanName=${koreanName}`;
  if (userId) params += `&userId=${userId}`;
  if (workplaceId) params += `&workplaceId=${workplaceId}`;
  if (from) params += `&from=${from}`;
  if (to) params += `&to=${to}`;
  if (fromDate) params += `&fromDate=${fromDate}`;
  if (toDate) params += `&toDate=${toDate}`;
  if (kioskId) params += `&kioskId=${kioskId}`;
  if (smartWatchId) params += `&smartWatchId=${smartWatchId}`;
  if (workerId) params += `&workerId=${workerId}`;
  if (edgeBoxId) params += `&edgeBoxId=${edgeBoxId}`;
  if (deviceId) params += `&deviceId=${deviceId}`;
  if (machineId) params += `&machineId=${machineId}`;
  if (direction) params += `&direction=${direction}`;
  if (startMeasureTime) params += `&startMeasureTime=${startMeasureTime}`;
  if (endMeasureTime) params += `&endMeasureTime=${endMeasureTime}`;
  if (smartChairId) params += `&smartChairId=${smartChairId}`;

  return encodeURI(params);
}

export function getSearchParams(conditions) {
  const code = conditions.code;
  const name = conditions.name;
  const type = conditions.type;
  const siteId = conditions.siteId;
  const siteName = conditions.siteName;
  const page = conditions.page;
  const pageSize = conditions.pageSize;
  const userId = conditions.userId;
  const requesterId = conditions.requesterId;
  const collectionCode = conditions.collectionCode;
  const statusCodes = conditions.statusCodes;
  const collectorId = conditions.collectorId;

  let params = '?';
  let a = '';

  //new.ver
  if (page && pageSize) a = '&';
  if (name && (page || pageSize)) a = '&';
  if (code && (page || pageSize)) a = '&';
  if (type && (page || pageSize)) a = '&';
  if (siteId && (page || pageSize)) a = '&';
  if (siteName && (page || pageSize)) a = '&';
  if (userId && (page || pageSize)) a = '&';
  if (requesterId && (page || pageSize)) a = '&';
  if (collectionCode && (page || pageSize)) a = '&';
  if (statusCodes && (page || pageSize)) a = '&';
  if (collectorId && (page || pageSize)) a = '&';

  // pre.ver
  // if (name && page || name && pageSize) a = '&';
  // if (name && pageSize) a = '&';
  // if (code && page) a = '&';
  // if (code && pageSize) a = '&';
  // if (siteName && page) a = '&';
  // if (siteName && pageSize) a = '&';
  // if (page && pageSize) a = '&';

  if (page || pageSize) params += `page=${page < 0 ? 0 : page}&pageSize=${pageSize}`;
  if (name) params += `${a}name=${name}`;
  if (type) params += `${a}type=${type}`;
  if (siteId) params += `${a}siteId=${siteId}`;
  if (siteName) params += `${a}siteName=${siteName}`;
  if (code) params += `${a}code=${code}`;
  if (userId) params += `${a}userId=${userId}`;
  if (requesterId) params += `${a}requesterId=${requesterId}`;
  if (collectionCode) params += `${a}collectionCode=${collectionCode}`;
  if (statusCodes) params += `${a}statusCodes=${statusCodes}`;
  if (collectorId) params += `${a}collectorId=${collectorId}`;

  return encodeURI(params);
}