import createActionPagerFor from "../common/pagination/actionFor";

const scope = 'DEVICE';
const actions = {
	/**
	 * Define Action Types
	 */

	// Initialize
	INIT_SEARCH                 : `${scope}_INIT_SEARCH`,
	INIT_DATA                 : `${scope}_INIT_DATA`,

	CHANGE_VALUE                 : `${scope}_CHANGE_VALUE`,

	CHANGE_VALUE_SEARCH_EDGEBOX                 : `${scope}_CHANGE_VALUE_SEARCH_EDGEBOX`,
	CHANGE_VALUE_SEARCH_SMARTWATCH                 : `${scope}_CHANGE_VALUE_SEARCH_SMARTWATCH`,
	CHANGE_VALUE_SEARCH_KIOSK                 : `${scope}_CHANGE_VALUE_SEARCH_KIOSK`,

	CHANGE_VALUE_DEVICE_INFO_EDGEBOX                 : `${scope}_CHANGE_VALUE_DEVICE_INFO_EDGEBOX`,
	CHANGE_VALUE_DEVICE_INFO_SMARTWATCH                 : `${scope}_CHANGE_VALUE_DEVICE_INFO_SMARTWATCH`,
	CHANGE_VALUE_DEVICE_INFO_KIOSK                 : `${scope}_CHANGE_VALUE_DEVICE_INFO_KIOSK`,

	FETCH_LIST_EDGEBOX                 : `${scope}_FETCH_LIST_EDGEBOX`,
	FETCH_LIST_SUCCESS_EDGEBOX                 : `${scope}_FETCH_LIST_SUCCESS_EDGEBOX`,
	FETCH_LIST_SMARTWATCH                 : `${scope}_FETCH_LIST_SMARTWATCH`,
	FETCH_LIST_SUCCESS_SMARTWATCH                 : `${scope}_FETCH_LIST_SUCCESS_SMARTWATCH`,
	FETCH_LIST_KIOSK                 : `${scope}_FETCH_LIST_KIOSK`,
	FETCH_LIST_SUCCESS_KIOSK                 : `${scope}_FETCH_LIST_SUCCESS_KIOSK`,


	/** initialize **/
	initData: () => ({
		type: actions.INIT_DATA,
	}),
	initSearch: () => ({
		type: actions.INIT_SEARCH,
	}),

	changeValue: (type, prop, value) => ({
		type: actions[type],
		prop,
		value,
	}),

	fetchListEdgebox: (conditions) => ({
		type: actions.FETCH_LIST_EDGEBOX,
		conditions,
	}),
	fetchListSuccessEdgebox: (payload) => ({
		type: actions.FETCH_LIST_SUCCESS_EDGEBOX,
		payload,
	}),
	fetchListSmartwatch: (conditions) => ({
		type: actions.FETCH_LIST_SMARTWATCH,
		conditions,
	}),
	fetchListSuccessSmartwatch: (payload) => ({
		type: actions.FETCH_LIST_SUCCESS_SMARTWATCH,
		payload,
	}),
	fetchListKiosk: (conditions) => ({
		type: actions.FETCH_LIST_KIOSK,
		conditions,
	}),
	fetchListSuccessKiosk: (payload) => ({
		type: actions.FETCH_LIST_SUCCESS_KIOSK,
		payload,
	}),

	// Page
	setListPagerEdgebox: createActionPagerFor('EDGEBOX'),
	setListPagerSmartwatch: createActionPagerFor('SMARTWATCH'),
	setListPagerKiosk: createActionPagerFor('KIOSK'),
};

export default actions;
export {
	scope
}