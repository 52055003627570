import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
// import Dashboard from 'app-store-front/src/containers/common/Dashboard';
import asyncComponent from '../helpers/AsyncFunc';
import {stripTrailingSlash} from "../helpers/utility";

const routes = [
	// Common
	{
		path: '',
		component: asyncComponent(() => import('../containers/Dashboard')),
	},
	{
		path: 'dashboard',
		component: asyncComponent(() => import('../containers/Dashboard')),
	},
	{
		path: 'profile',
		component: asyncComponent(() => import('../containers/Profile/')),
	},

	// 기기별 현황
	{
		path: 'deviceStatus',
		component: asyncComponent(() => import('../containers/DeviceStatus/DeviceStatus')),
	},

	// workplace device status
	{
		path: 'workplaceDeviceStatus',
		component: asyncComponent(() => import('../containers/WorkplaceDeviceStatus/WorkplaceStatus')),
	},
	{
		path: 'workplaceDeviceStatus/:id',
		component: asyncComponent(() => import('../containers/WorkplaceDeviceStatus/WorkplaceStatusDetail')),
	},

	// Worker
	{
		path: 'worker',
		component: asyncComponent(() => import('../containers/Worker/Worker')),
	},
	// Worker Detail
	{
		path: 'worker/:id',
		component: asyncComponent(() => import('../containers/Worker/WorkerDetail')),
	},

	// deviceSearch
	{
		path: 'deviceSearch',
		component: asyncComponent(() => import('../containers/DeviceSearch/DeviceSearch')),
	},

	// deviceSearch
	{
		path: 'edgebox/:id',
		component: asyncComponent(() => import('../containers/Edgebox/Edgebox')),
	},

	// 아래 라우터는 맨 마지막에 위치해야 함
	{
		path: '*',
		component: asyncComponent(() => import('../containers/common/Page/404')),
	},
];

class AppRouter extends Component {

	render() {
		const {
			style
		} = this.props;
		const url = stripTrailingSlash(this.props.url);
		return (
			<Switch style={style}>
				{routes.map((singleRoute) => {
					const { path, exact, ...otherProps } = singleRoute;
					return <Route
						exact={exact !== false}
						key={path}
						path={`${url}/${path}`}
						{...otherProps} />;
				})}
			</Switch>
		);
	}
}

export default AppRouter;
