/**
 * Define Action Type & Action Creator
 */
const scope = 'AUTH';
const actions = {
	/**
	 * Define Action Types
	 */

	INIT_AUTH_FORM			    : `${scope}_INIT_AUTH_FORM`,

	CHECK_AUTHORIZATION         : `${scope}_CHECK_AUTHORIZATION`,
	CHECK_AUTHORIZATION_SUCCESS : `${scope}_CHECK_AUTHORIZATION_SUCCESS`,

	LOGOUT                      : `${scope}_LOGOUT`,

	/**
	 * Define Action Creators
	 */

	initAuthForm: () => ({
		type: actions.INIT_AUTH_FORM,
	}),

	checkAuthorization: (ifValid, ifInvalid, resumeDeferredTasks) => ({
		type: actions.CHECK_AUTHORIZATION,
		ifValid,
        ifInvalid,
		resumeDeferredTasks,
	}),

	checkAuthorizationSuccess: (payload) => ({
		type: actions.CHECK_AUTHORIZATION_SUCCESS,
		payload,
	}),

	logout: (frontGatewayUrl) => ({
		type: actions.LOGOUT,
		frontGatewayUrl,
	}),

};

export default actions;
export {
	scope
}
