import createActionPagerFor from "../common/pagination/actionFor";

const scope = 'DEVICE_STATUS';
const actions = {
	/**
	 * Define Action Types
	 */

	// Initialize
	INIT_SEARCH                 : `${scope}_INIT_SEARCH`,
	INIT_SEARCH_SELECT                 : `${scope}_INIT_SEARCH_SELECT`,

	CHANGE_SEARCH_VALUE                 : `${scope}_CHANGE_SEARCH_VALUE`,
	CHANGE_SEARCH_DATE_RANGE_VALUE                 : `${scope}_CHANGE_SEARCH_DATE_RANGE_VALUE`,

	FETCH_LIST                 : `${scope}_FETCH_LIST`,
	FETCH_LIST_SUCCESS                 : `${scope}_FETCH_LIST_SUCCESS`,


	/** initialize **/
	initSearch: () => ({
		type: actions.INIT_SEARCH,
	}),
	initSelectValue: () => ({
		type: actions.INIT_SEARCH_SELECT,
	}),

	changeValue: (type, prop, value) => ({
		type: actions[type],
		prop,
		value,
	}),

	fetchList: (conditions) => ({
		type: actions.FETCH_LIST,
		conditions,
	}),

	fetchListSuccess: (payload) => ({
		type: actions.FETCH_LIST_SUCCESS,
		payload,
	}),

	// Page
	setListPager: createActionPagerFor(scope),
	setSiteListPager: createActionPagerFor('DEVICE_STATUS'),
};

export default actions;
export {
	scope
}