import {call, put, spawn, takeEvery} from 'redux-saga/effects';
import {spinnerActions} from 'gurmwi-share-front/share/spinner';
import {errorMessage} from 'gurmwi-share-front/share/feedback/Message/message';
import {clearToken, clearUserID} from 'gurmwi-share-front/share/helpers/utility';
import {setHeaders} from "../../settings/serviceConfig";

import actions from './actions';
import * as service from './serviceApi';

export function* checkAuthorizationSaga(action) {
  yield put(spinnerActions.start());
  try {
    const response = yield call(service.authorization);
    const user = response.data;
    if (user) {
      yield put(actions.checkAuthorizationSuccess(user));
      yield sessionStorage.setItem('userID', user.userId);
      if (user.positionAuthority[0]) {
        if (typeof action.ifValid === 'function') {
          yield action.ifValid(user.positionAuthority[0]);
        }
      }
    } else {
      if (response.headers['reissue_token']) {
        yield put(actions.checkAuthorization(action.ifValid, action.ifInvalid));
      } else {
        clearToken();
        clearUserID();
        setHeaders();
        if (typeof action.ifInvalid === 'function') {
          action.ifInvalid();
        }
      }
    }
    if (typeof action.resumeDeferredTasks === 'function') {
      yield action.resumeDeferredTasks();
    }
    yield put(spinnerActions.success());
  } catch (error) {
    yield put(spinnerActions.failure());
    clearToken();
    clearUserID();
    setHeaders();
    errorMessage('토큰 유효성 확인');
    if (typeof action.ifInvalid === 'function') {
      action.ifInvalid();
    }
  }
}

export function* logout(action) {
  yield put(spinnerActions.start());
  try {
    const response = yield call(service.logout);
    if (response) {
      yield put(actions.initAuthForm());
      clearToken();
      clearUserID();
      // setHeaders();
      window.location.href=action.frontGatewayUrl;
    }
  } catch (error) {
    yield put(spinnerActions.failure());
    errorMessage('로그아웃');
    clearToken();
    clearUserID();
    // setHeaders();
  }
}

export function* watchSaga() {
  yield takeEvery(actions.LOGOUT, logout);
  yield takeEvery(actions.CHECK_AUTHORIZATION, checkAuthorizationSaga);
}

export default function* rootSaga() {
  yield spawn(watchSaga);
}
