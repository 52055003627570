import axios, {context}  from '../../settings/serviceConfig';
import {getParams} from "../../helpers/utility";

const fetchList = (conditions) => {
  const param = getParams(conditions)
  return axios.get(`${context}/dashboardByDevice/devices/devicesMapping`+param);
};
const fetchListMain = (conditions) => {
  const param = getParams(conditions)
  return axios.get(`${context}/dashboardByDevice/devices/countTotalDeviceStatus`+param);
};
const fetchListKiosk = (conditions) => {
  const param = getParams(conditions)
  return axios.get(`${context}/dashboardByDevice/devices/findKioskStatusByWorkplaceAndKioskIdAndWorkerId`+param);
};
// 스마트 워치 디바이스 데이터 호출
const fetchListSmartWatch = (conditions) => {
  const param = getParams(conditions)
  return axios.get(`${context}/device/findSmartWatchStatusByWorker`+param);
};
// 스마트 방석 디바이스 데이터 호출
const fetchListSmartChair = (conditions) => {
  const param = getParams(conditions)
  return axios.get(`${context}/dashboardByDevice/device/cushion`+param);
};
const fetchListDetail = (conditions) => {
  const param = getParams(conditions)
  return axios.get(`${context}/devices/countOfDeviceStatusByWorkerIdPaging`+param);
};
const fetchListEdgeBox = (conditions) => {
  const param = getParams(conditions)
  return axios.get(`${context}/dashboardByDevice/device/findEdgeBoxIdByWorkplaceId`+param);
};
const fetchListEdgeBoxModal = (conditions) => {
  const param = getParams(conditions)
  return axios.get(`${context}/dashboardByDevice/device/findEdgeBoxStatusByWorkplaceAndTerm`+param);
};
const fetchWorkplaceInfo = (conditions) => {
  const param = getParams(conditions)
  return axios.get(`${context}/dashboardByDevice/device/findAllDeviceCntAndDeviceUsersCntByWorkplaceId`+param);
};

export {
  fetchList,
  fetchListMain,
  fetchListKiosk,
  fetchListSmartWatch,
  fetchListSmartChair,
  fetchListDetail,
  fetchListEdgeBox,
  fetchListEdgeBoxModal,
  fetchWorkplaceInfo
};