import createActionPagerFor from "../common/pagination/actionFor";

const scope = 'EDGEBOX';
const actions = {
	/**
	 * Define Action Types
	 */

	// Initialize
	INIT_DATA                 : `${scope}_INIT_DATA`,
	INIT_DATA_EXCEPT_DATE                 : `${scope}_INIT_DATA_EXCEPT_DATE`,

	// boolean 타입의 상태를 변경
	CHANGE_VALUE                 : `${scope}_CHANGE_VALUE`,

	CHANGE_CURRENT_PAGE : `${scope}_CHANGE_CURRENT_PAGE`,


	CHANGE_EDGEBOX_STATE_VALUE                 : `${scope}_CHANGE_EDGEBOX_STATE_VALUE`,

	FETCH_LIST                 : `${scope}_FETCH_LIST`,
	FETCH_LIST_SUCCESS                 : `${scope}_FETCH_LIST_SUCCESS`,

	UPDATE_HEALTH_DATA                 : `${scope}_UPDATE_HEALTH_DATA`,
	CALCULATE_CHART_CONFIG                 : `${scope}_CALCULATE_CHART_CONFIG`,

	SET_LOADING                 : `${scope}_SET_LOADING`,


	/** initialize **/
	initData: () => ({
		type: actions.INIT_DATA,
	}),

	initDataExceptDate: () => ({
		type: actions.INIT_DATA_EXCEPT_DATE,
	}),

	initSearch: () => ({
		type: actions.INIT_SEARCH,
	}),

	initEdgeboxList: () => ({
		type: actions.INIT_EDGEBOX_LIST,
	}),

	changeValue: (type, prop, value) => ({
		type: actions[type],
		prop,
		value,
	}),

	changeCurrentPage: (prop, value) => ({
		type: actions.CHANGE_CURRENT_PAGE,
	}),

	fetchList: (conditions, callback) => ({
		type: actions.FETCH_LIST,
		conditions,
		callback,
	}),
	fetchListSuccess: (payload) => ({
		type: actions.FETCH_LIST_SUCCESS,
		payload,
	}),

	fetchListDetail: (conditions) => ({
		type: actions.FETCH_LIST_DETAIL,
		conditions,
	}),
	fetchListDetailSuccess: (payload) => ({
		type: actions.FETCH_LIST_DETAIL_SUCCESS,
		payload,
	}),
	calculateChartConfig: (co2Data, pm1Data, pm10Data, pm25Data, humidityData, temperatureData, vocData) => ({
		type: actions.CALCULATE_CHART_CONFIG,
		co2Data,
		pm1Data,
		pm10Data,
		pm25Data,
		humidityData,
		temperatureData,
		vocData,
	}),
	updateHealthData: (list, co2Data, pm1Data, pm10Data, pm25Data, humidityData, temperatureData, vocData) => ({
		type: actions.UPDATE_HEALTH_DATA,
		list,
		co2Data,
		pm1Data,
		pm10Data,
		pm25Data,
		humidityData,
		temperatureData,
		vocData,
	}),

	setLoading: (prop, value) => ({
		type: actions.SET_LOADING,
		prop,
		value,
	}),

	// Page
	setListPager: createActionPagerFor(scope),
};

export default actions;
export {
	scope
}