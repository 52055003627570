import { combineReducers } from "redux";
import { Map } from 'immutable';

import actions from './actions';
import { convertNestedByArray } from "../../helpers/utility";

/**
 * Set Initial State
 */
const initForm = Map({
	// TODO companyId, companyName 추가
	additionalInfo: {
		COMPANY: '',
	},
	userId: "",
	userName: "",
	positionAuthority: "",
	token: null,
	loaded: '',
});

const initState = Map({
	form: Map().merge(initForm),
});

const form = convertNestedByArray(initState.get('form').toJS(), 'form');

/**
 * Define Reducer
 */
export function authReducer(state = initState, action) {
	switch (action.type) {
		//
		case actions.INIT_AUTH_FORM:
			return applyInitAuthForm(state, action);

		case actions.CHECK_AUTHORIZATION_SUCCESS:
			return applyCheckAuthorizationSuccess(state, action);

		default:
			return state;
	}
}

/**
 * Define Reducer Pure function
 */

function applyInitAuthForm(state, action) {
	return state.set('form', Map().merge(initForm));
}

function applyCheckAuthorizationSuccess(state, action) {

	// TODO companyId, companyName 추가
	// const authority = action.payload.authorities[0];

	return state.setIn(form.userId, action.payload.userId)
		.setIn(form.userName, action.payload.userName)
		.setIn(form.token, action.payload.token)
		.setIn(form.positionAuthority, action.payload.positionAuthority[0])
		.setIn(form.loaded, 'loaded')
		.setIn(form.additionalInfo, action.payload.userInfo)
}

export default combineReducers({
	index: authReducer,
});