import createActionPagerFor from "../common/pagination/actionFor";

const scope = 'WORKER';
const actions = {
	/**
	 * Define Action Types
	 */

	// Initialize
	INIT_SEARCH                 : `${scope}_INIT_SEARCH`,
	INIT_DATA                 : `${scope}_INIT_DATA`,

	CHANGE_VALUE                 : `${scope}_CHANGE_VALUE`,
	CHANGE_SEARCH_VALUE                 : `${scope}_CHANGE_SEARCH_VALUE`,

	FETCH_MODAL                 : `${scope}_FETCH_MODAL`,
	FETCH_MODAL_SUCCESS                 : `${scope}_FETCH_MODAL_SUCCESS`,


	/** initialize **/
	initData: () => ({
		type: actions.INIT_DATA,
	}),
	initSearch: () => ({
		type: actions.INIT_SEARCH,
	}),

	changeValue: (type, prop, value) => ({
		type: actions[type],
		prop,
		value,
	}),

	fetchModal: (conditions) => ({
		type: actions.FETCH_MODAL,
		conditions,
	}),
	fetchModalSuccess: (payload) => ({
		type: actions.FETCH_MODAL_SUCCESS,
		payload,
	}),

	// Page
	setListPager: createActionPagerFor(scope),
};

export default actions;
export {
	scope
}