const menu = [
    {
        key: 'lobby',
        label: 'home',
        roles: ['MAINTAINER', 'USER', 'GUEST'],
        leftIcon: 'home'
    },
    {
        key: 'admin',
        label: 'e-phs',                                 // 메뉴에 나올 텍스트를 입력한다.
        roles: ['MAINTAINER'],                          // 권한에 따라 다르게 설정한다.
        leftIcon: 'shop',                               // 해당 아이콘을 넣는다.
        children: [
            {
                key: 'workplaceDeviceStatus',
                label: '작업장별 기기현황',
                roles: ['MAINTAINER'],
            },
        ]
    },
    // {
    //     key: 'user',
    //     label: 'e-phs',                                 // 메뉴에 나올 텍스트를 입력한다.
    //     roles: ['USER'],                                // 권한에 따라 다르게 설정한다.
    //     leftIcon: 'shop',                               // 해당 아이콘을 넣는다.
    //     children: [
    //         {
    //             key: 'workplaceDeviceStatus/:id',
    //             label: '작업장 상세 조회',
    //             roles: ['USER'],
    //         },
    //     ]
    // },
    {
        key: 'device',
        label: '디바이스 정보검색',                        // 메뉴에 나올 텍스트를 입력한다.
        roles: ['FIXME'],                               // 권한에 따라 다르게 설정한다.
        leftIcon: 'shop',                               // 해당 아이콘을 넣는다.
        children: [
            {
                key: 'deviceSearch',
                label: '디바이스 검색',
                roles: ['FIXME'],
            },
        ]
    },
    {
        key: 'worker',
        label: '근로자 검색',                                 // 메뉴에 나올 텍스트를 입력한다.
        roles: ['FIXME'],                                   // 권한에 따라 다르게 설정한다.
        leftIcon: 'shop',
    },
    {
        key: 'edgebox/EB01',
        label: '엣지박스',                                 // 메뉴에 나올 텍스트를 입력한다.
        roles: ['MAINTAINER'],                                   // 권한에 따라 다르게 설정한다.
        leftIcon: 'shop',
    },
];
export default menu;

export function isAllowed(key, authority) {
    if (authority !== '' || key === '') {
        return true;
    }

    const menuItem = menu.find((item) => {
        return (item.key === key) || (item.children && item.children.some((subitem) => (subitem.key === key)));
    });

    if (menuItem) {
        return menuItem.roles.includes(authority);
    }

    return false;
};