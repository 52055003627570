import {createSelector} from 'reselect';

const indexSelector = {
  form: createSelector(
    state => state.auth.index.get('form'),
    form => ({
      additionalInfo: form.get('additionalInfo'),
      userId: form.get('userId'),
      userName: form.get('userName'),
      positionAuthority: form.get('positionAuthority'),
      token: form.get('token'),
      loaded: form.get('loaded'),
    }),
  ),
};

export {
  indexSelector,
};
