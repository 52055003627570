import {combineReducers} from "redux";
import {Map, List} from "immutable";

import actions, {scope} from './actions';
import {convertNestedByArray} from "../../helpers/utility";
import pagerReducerFor from "../common/pagination/reducerFor";

/**
 * Set Initial State
 */
const getDate = () => {
    var today = new Date();
    // var yesterday = new Date(today.setDate(today.getDate() - 1))

    let year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    if (month < 10) {
        month = '0' + month;
    }
    if (day < 10) {
        day = '0' + day;
    }

    return year + '-' + month + '-' + day;
}

const initSearch = Map({
    workplaceId: '',
    workerId: "",
    from: getDate(),
    to: getDate(),
});

const initModalSearch = Map({
    deviceId: '',
    workerId: '',
    type: '',
});

const initWorkplaceInfo = Map({
    workplaceNameAndCountWorker:{
        companyName: '',
        companyId: '',
        countOfWorkers: ''
    },
    sumOfDevicesCnt: {
        workplaceId: '',
        sumOfSmartWatchCnt: '0',
        sumOfInsoleCnt: '0',
        sumOfSmartPatchCnt: '0',
        sumOfSmartChairCnt: '0',
        sumOfKioskCnt: '0',
        sumOfFallDetectorCnt: '0',
    },
    countOfDeviceUsedWorker: {
        EDGE_BOX: '0',
        KIOSK: '0',
        SMART_CHAIR: '0',
        SMART_INSOLE: '0',
        SMART_PATCH: '0',
        SMART_WATCH: '0',
        FALL_DETECTOR: '0',
        EDGE_BOX_RAW: '0',
    },
});

const initIndexState = Map({
    search: Map().merge(initSearch),
    modalSearch: Map().merge(initModalSearch),
    mainList: List(),
    modalList: List(),
    list: List(),
    edgeList: List(),
    detailList: List(),
    workplaceInfo: Map().merge(initWorkplaceInfo), //작업장 상세정보(디바이스사용자수,데이터수집건수)
    loading: true,      // 작업장별 현황 조회 로딩
    w_loading: true,    // 전체 작업장 목록 조회 로딩
    d_loading: true,    // 기기별 현황 조회 로딩
    m_loading: true,    // 디바이스 모달 로딩
    e_loading: true,    // 엣지박스 현황 조회 로딩
    datePickerValidation: '',
    showKioskModal: false,
    showSmartWatchModal: false,
    showEdgeBoxModal: false,
    showSmartChairModal: false
});

const search = convertNestedByArray(initIndexState.get('search').toJS(), 'search');
const modalSearch = convertNestedByArray(initIndexState.get('modalSearch').toJS(), 'modalSearch');
const workplaceInfo = convertNestedByArray(initIndexState.get('workplaceInfo').toJS(), 'workplaceInfo');

/**
 * Define Reducer
 */
export function indexReducer(state = initIndexState, action) {
    switch (action.type) {
        //
        case actions.INIT_SEARCH:
            return applyInitSearch(state);

        case actions.INIT_DATA:
            return applyInitData(state);
        case actions.INIT_MODAL_DATA:
            return applyInitModalData(state);

        case actions.INIT_SEARCH_SELECT:
            return applyInitSearchSelectValue(state);

        case actions.FETCH_LIST_SUCCESS:
            return applyFetchListSuccess(state, action);
        case actions.FETCH_LIST_DETAIL_SUCCESS:
            return applyFetchListDetailSuccess(state, action);
        case actions.FETCH_LIST_MAIN_SUCCESS:
            return applyFetchListMainSuccess(state, action);
        case actions.FETCH_LIST_KIOSK_SUCCESS:
            return applyFetchListModalSuccess(state, action);
        // 응답받은 스마트 워치 API 데이터 저장
        case actions.FETCH_LIST_SMART_WATCH_SUCCESS:
            return applyFetchListModalSuccess(state, action);
        // 응답받은 스마트 방석 API 데이터 저장
        case actions.FETCH_LIST_SMART_CHAIR_SUCCESS:
            return applyFetchListModalSuccess(state, action);
        case actions.FETCH_LIST_EDGE_BOX_MODAL_SUCCESS:
            return applyFetchListModalSuccess(state, action);
        case actions.FETCH_LIST_EDGE_BOX_SUCCESS:
            return applyFetchListEdgeBoxSuccess(state, action);

        case actions.FETCH_WORKPLACE_INFO_SUCCESS:
            return applyFetchWorkplaceInfoSuccess(state, action);

        case actions.CHANGE_VALUE:
            return applyChangeValue(state, action);
        case actions.CHANGE_SEARCH_VALUE:
            return applyChangeSearchValue(state, action);
        case actions.CHANGE_SEARCH_DATE_VALUE:
            return applyChangeSearchDateValue(state, action);
        case actions.CHANGE_DETAIL_SEARCH_VALUE:
            return applyChangeDetailSearchValue(state, action);
        case actions.CHANGE_MODAL_SEARCH_VALUE:
            return applyChangeModalSearchValue(state, action);

        case actions.CHANGE_WORKPLACE_ID_VALUE:
            return applyChangeWorkplaceIdValue(state, action);

        case actions.SET_LOADING:
            return applySetLoading(state, action);
        default:
            return state;
    }
}

/**
 * Define Reducer Pure function
 */
function applyInitData(state) {
    return state.set('list', List())
        .set('detailList', List())
        .set('workplaceInfo', Map().merge(initWorkplaceInfo))
        .set('modalSearch', Map().merge(initModalSearch))
        .set('edgeList', List())
}

function applyInitSearch(state) {
    if (state.getIn(search.from) === '' && state.getIn(search.to) === '') {
        state.setIn(search.from, getDate())
            .setIn(search.to, getDate())
    }
    return state.setIn(search.workerId, '')
        .set('datePickerValidation', '')
        .set('d_loading', true)
        .set('w_loading', true)
        .set('m_loading', true)
        .set('loading', true)
}

function applyInitModalData(state) {
    return state.set('modalList', List())
        .set('modalSearch', Map().merge(initModalSearch))
}

function applyInitSearchSelectValue(state) {
    return state.setIn(search.device, '');
}

function applyFetchListSuccess(state, action) {
    return state.set('list', List().merge(action.payload))
}

function applyFetchListDetailSuccess(state, action) {
    return state.set('detailList', List().merge(action.payload.items))
}

function applyFetchListMainSuccess(state, action) {
    return state.set('mainList', List().merge(action.payload))
}

function applyFetchListModalSuccess(state, action) {
    return state.set('modalList', List().merge(action.payload))
}

function applyFetchListEdgeBoxSuccess(state, action) {
    return state.set('edgeList', List().merge(action.payload))
}

function applyChangeValue(state, action) {
    return state.set(action.prop, action.value)
}

function applyChangeSearchValue(state, action) {
    return state.setIn(search[action.prop], action.value)
}

function applyChangeModalSearchValue(state, action) {
    return state.setIn(modalSearch[action.prop], action.value)
}
function applyFetchWorkplaceInfoSuccess(state, action) {
    return state
        .setIn(workplaceInfo.countOfDeviceUsedWorker, action.payload.countOfDeviceUsedWorker)
        .setIn(workplaceInfo.sumOfDevicesCnt, action.payload.sumOfDevicesCnt)
        .setIn(workplaceInfo.workplaceNameAndCountWorker, action.payload.workplaceNameAndCountWorker)
}

function applyChangeSearchDateValue(state, action) {
    if (action.value[0] && action.value[1]) {
        // 시작일자와 종료일자는 최대 1달
        let status;
        let s_from = new Date(action.value[0]);
        let s_to = new Date(action.value[1]);

        var currDay = 24 * 60 * 60 * 1000;
        if ((s_to - s_from) / currDay > 30)
            status = "r_error";
        else
            status = '';

        // 종료일자가 오늘 날자를 지나지 않게
        let t_status = getDate();
        t_status = new Date(t_status);
        if (s_to > t_status)
            status = 't_error';

        return state
            .setIn(search.from, action.value[0])
            .setIn(search.to, action.value[1])
            .set('datePickerValidation', status)
    }
}

function applyChangeWorkplaceIdValue(state, action) {
    return state.setIn(search[action.prop], action.value)
}

function applyChangeDetailSearchValue(state, action) {
    return state.setIn(search[action.prop], action.value)
}

function applySetLoading(state, action) {
    return state.set(action.prop, action.value)
}

/**
 * Define Initial Edit State
 */

const initEditForm = Map({
    code: '',
    name: '',
});

const initEditState = Map({
    editForm: Map().merge(initEditForm),

});

// const editForm = convertNestedByArray(initEditState.get('editForm').toJS(), 'editForm');

/**
 * Define Edit Reducer
 *
 * @param state
 * @param action
 * @returns {*}
 */

export function editReducer(state = initEditState, action) {
    switch (action.type) {

        // case actions.INIT_CREATE:
        //     return applyInitCreate(state);

        default:
            return state;
    }
}

//editReducer
// function applyInitCreate(state) {
//     return state.set('editForm', Map().merge(initEditForm)).set('file', '')
// }


export default combineReducers({
    index: indexReducer,
    edit: editReducer,
    indexPager: pagerReducerFor(scope),
    indexPagerModal: pagerReducerFor('DEVICE_MODAL'),
    indexPagerEdgeBox: pagerReducerFor('EDGE_BOX'),
});