import { combineReducers } from "redux";
import { List, Map } from "immutable";

import actions, { getView } from './actions';
import { getDefaultPath } from '../../helpers/urlSync';
import { convertNestedByArray } from "../../helpers/utility";

const preKeys = getDefaultPath();

/**
 * Define Initial Category State
 */
const initCateCodeState = Map({
	categories: List(),
});


export function cateCodeReducer(state = initCateCodeState, action) {
	switch (action.type) {

		case actions.CATEGORY_LIST_SUCCESS:
			return applyGetCateCodeListSuccess(state, action);

		default:
			return state;
	}
}

// Search Code List
function applyGetCateCodeListSuccess(state, action) {
	return state.set('categories', List().merge(action.payload));
}

/**
 * Set Initial State
 */
const initForm = Map({
	collapsed: window.innerWidth <= 1220,
	view: getView(window.innerWidth),
	height: window.innerHeight,
	openDrawer: false,
	openKeys: preKeys,
	current: preKeys,
	gurmwimeta: {},
	deferredTasks: [],
});

const initMenulist = [];

const initSite = Map({
	siteId: '',         //사이트아이디
	siteCode: '',       //사이트코드
	siteName: '',       //사이트이름
	siteAuthority: '',  //사이트 권한
	positionName: '',  //사이트 권한
});

const initIndexState = Map({
	form: Map().merge(initForm),
	menulist: List().merge(initMenulist),
	site: Map().merge(initSite),
});

const form = convertNestedByArray(initIndexState.get('form').toJS(), 'form');

/**
 * Define Reducer
 */
export function indexReducer(state = initIndexState, action) {
	switch (action.type) {
		//
		case actions.GET_GURMWI_META_SUCCESS:
			return applygGetGurmwiMetaSuccess(state, action);

		case actions.COLLPSE_CHANGE:
			return applyCollapseChange(state);

		case actions.COLLPSE_OPEN_DRAWER:
			return applyCollapseOpenDrawer(state);

		case actions.TOGGLE_ALL:
			if (state.get('view') !== action.view || action.height !== state.get('height')) {
				return applyToggleAll(state, action);
			}
			break;

		case actions.CHANGE_OPEN_KEYS:
			return applyChangeOpenKeys(state, action);

		case actions.CHANGE_CURRENT:
			return applyChangeCurrent(state, action);

		case actions.CLOSE_ALL:
			return applyCloseAll(state);

		case actions.ADD_DEFERRED_TASKS:
		    return addDeferredTasks(state, action);

		case actions.EMPTY_DEFERRED_TASKS:
		    return emptyDeferredTasks(state, action);

		case actions.FETCH_MEMBERSHIP_SUCCESS:
			return applyChangeMembership(state, action);

		default:
			return state;
	}

	return state;
}

/**
 * Define Reducer Pure function
 */
function applygGetGurmwiMetaSuccess(state, action) {
	return state.setIn(form.gurmwimeta, action.payload);
}

function applyCollapseChange(state) {
	return state.setIn(form.collapsed, !state.getIn(form.collapsed));
}

function applyCollapseOpenDrawer(state) {
	return state.setIn(form.openDrawer, !state.getIn(form.openDrawer));
}

function applyToggleAll(state, action) {

	const height = action.height ? action.height : state.get('height');
	return state.setIn(form.collapsed, action.collapsed)
		.setIn(form.view, action.view)
		.setIn(form.height, height);
}

function applyChangeOpenKeys(state, action) {
	return state.setIn(form.openKeys, action.openKeys);
}

function applyChangeCurrent(state, action) {
	return state.setIn(form.current, action.current);
}

function applyCloseAll(state, action) {
	return state.setIn(form.current, [])
		.setIn(form.openKeys, []);
}

function addDeferredTasks(state, action) {
    const deferredTasks = [ ...state.getIn(form.deferredTasks) ];
    return state.setIn(form.deferredTasks, deferredTasks.concat(action.tasks));
}

function emptyDeferredTasks(state, action) {
    return state.setIn(form.deferredTasks, []);
}

function applyChangeMembership(state, action) {
	return state.set('site', Map().merge(action.payload));
}

export default combineReducers({
	index: indexReducer,
	categories: cateCodeReducer,
});