import {call, put, spawn, takeEvery} from 'redux-saga/effects';
import {spinnerActions} from 'gurmwi-share-front/share/spinner';
import {infoMessage} from 'gurmwi-share-front/share/feedback/Message/message';
import {errorModal} from 'gurmwi-share-front/share/feedback/Modal/modal';
import actions from './actions';
import * as service from './serviceApi';
// import {createPagerPayload} from "../../helpers/utility";
// import {push} from "react-router-redux";
// import {appRootPath} from "../../settings";

export function* fetchListSaga(action) {
  yield put(spinnerActions.start());
  try {
    const response = yield call(service.fetchList, action.conditions);
    yield put(actions.fetchListSuccess(response.data));
    if (response.data.length < 1) {
      infoMessage('데이터가 존재하지 않습니다.');
    }
    yield put(spinnerActions.success());
  } catch (error) {
    yield put(spinnerActions.failure());
    errorModal('목록 조회', error);
  }
}


export function* watchSaga() {
  yield takeEvery(actions.FETCH_LIST, fetchListSaga);
}

export default function* rootSaga() {
  yield spawn(watchSaga);
}