import React from 'react';
import {Route, Router, Switch} from 'react-router-dom';
import {connect} from 'react-redux';

import {appRootPath} from '../settings';
import LayoutContainer from '../containers/common/Layout/LayoutContainer';
import asyncComponent from '../helpers/AsyncFunc';

const RestrictedRoute = ({component: Component, ...rest}) => (
  <Route
    {...rest}
    render={(props) =>
      <Component {...props} />
    }
  />
);

const PublicRoutes = ({history}) => {
  return (
    <Router history={history}>
      <Switch>
        <RestrictedRoute path={`${appRootPath}`} component={LayoutContainer}/>
        <Route path="*" component={asyncComponent(() => import('../containers/common/Page/404'))}/>
      </Switch>
    </Router>
  );
};

const stateToProps = (state) => ({
});

export default connect(stateToProps)(PublicRoutes);
