import {combineReducers} from "redux";
import {Map, List} from "immutable";

import actions, {scope} from './actions';
import {convertNestedByArray} from "../../helpers/utility";
import pagerReducerFor from "../common/pagination/reducerFor";

/**
 * Set Initial State
 */
const initEdgeboxState = Map({
    edgeboxId: '',
    startMeasureTime: '',
    endMeasureTime: '',
    measureDate: '',
    measureStartTime: '',
    measureEndTime: '',
});
const initHealthDataState = Map({
    list: [],
    averageValue: '',
    yMin: '',
    yMax: '',
    yRange: '',
});

const initIndexState = Map({
    edgeboxState: Map().merge(initEdgeboxState),
    co2HealthDataState: Map().merge(initHealthDataState),
    pm1HealthDataState: Map().merge(initHealthDataState),
    pm10HealthDataState: Map().merge(initHealthDataState),
    pm25HealthDataState: Map().merge(initHealthDataState),
    humidityHealthDataState: Map().merge(initHealthDataState),
    temperatureHealthDataState: Map().merge(initHealthDataState),
    vocHealthDataState: Map().merge(initHealthDataState),

    list: List(),

    edgeboxIdList: [],

    baseStartMeasureTime: '',
    baseMeasureDate: '',
    loading: true,
    intervalId: 0,

    // raw 데이터 모달의 상태(버튼을 누르기전에는 default false)
    showRawDataModal: false,

});

const edgeboxState = convertNestedByArray(initIndexState.get('edgeboxState').toJS(), 'edgeboxState');
const co2HealthDataState = convertNestedByArray(initIndexState.get('co2HealthDataState').toJS(), 'co2HealthDataState');
const pm1HealthDataState = convertNestedByArray(initIndexState.get('pm1HealthDataState').toJS(), 'pm1HealthDataState');
const pm10HealthDataState = convertNestedByArray(initIndexState.get('pm10HealthDataState').toJS(), 'pm10HealthDataState');
const pm25HealthDataState = convertNestedByArray(initIndexState.get('pm25HealthDataState').toJS(), 'pm25HealthDataState');
const humidityHealthDataState = convertNestedByArray(initIndexState.get('humidityHealthDataState').toJS(), 'humidityHealthDataState');
const temperatureHealthDataState = convertNestedByArray(initIndexState.get('temperatureHealthDataState').toJS(), 'temperatureHealthDataState');
const vocHealthDataState = convertNestedByArray(initIndexState.get('vocHealthDataState').toJS(), 'vocHealthDataState');

/**
 * Define Reducer
 */
export function indexReducer(state = initIndexState, action) {
    switch (action.type) {
        //
        case actions.INIT_DATA:
            return applyInitData(state);
        case actions.INIT_DATA_EXCEPT_DATE:
            return applyInitDataExceptDate(state);
        case actions.FETCH_LIST_SUCCESS:
            return applyFetchListSuccess(state, action);
        case actions.CALCULATE_CHART_CONFIG:
            return applyCalculateChartConfig(state, action);
        case actions.UPDATE_HEALTH_DATA:
            return applyUpdateHealthData(state, action);
        case actions.CHANGE_EDGEBOX_STATE_VALUE:
            return applyChangeEdgeboxStateValue(state, action);

        // CHANGE_VALUE 일 경우 applyChangeValue 실행
        case actions.CHANGE_VALUE:
            return applyChangeValue(state, action);

        case actions.CHANGE_CURRENT_PAGE:
            return applyChangePage(state, action);


        case actions.SET_LOADING:
            return applySetLoading(state, action);
        default:
            return state;
    }
}

/**
 * Define Reducer Pure function
 */
function applyInitData(state) {
    const edgeboxIdList = [];

    for (let i = 1; i <= 36; i++) {
        const label = `EB${i.toString().padStart(2, '0')}`;
        const value = `EB${i.toString().padStart(2, '0')}`;

        edgeboxIdList.push({ label, value });
    }
    edgeboxIdList.push({label: 'EB_Exhibit', value: 'EB_Exhibit'})
    return state
        .set('edgeboxIdList', edgeboxIdList)
        .set('edgeboxState', initEdgeboxState)
        .set('co2HealthDataState', initHealthDataState)
        .set('pm1HealthDataState', initHealthDataState)
        .set('pm10HealthDataState', initHealthDataState)
        .set('pm25HealthDataState', initHealthDataState)
        .set('humidityHealthDataState', initHealthDataState)
        .set('temperatureHealthDataState', initHealthDataState)
        .set('vocHealthDataState', initHealthDataState)
        .set('list', List())
}

function applyInitDataExceptDate(state) {
    const edgeboxIdList = [];

    for (let i = 1; i <= 36; i++) {
        const label = `EB${i.toString().padStart(2, '0')}`;
        const value = `EB${i.toString().padStart(2, '0')}`;

        edgeboxIdList.push({ label, value });
    }
    edgeboxIdList.push({label: 'EB_Exhibit', value: 'EB_Exhibit'})
    return state
        .set('edgeboxIdList', edgeboxIdList)
        .set('co2HealthDataState', initHealthDataState)
        .set('pm1HealthDataState', initHealthDataState)
        .set('pm10HealthDataState', initHealthDataState)
        .set('pm25HealthDataState', initHealthDataState)
        .set('humidityHealthDataState', initHealthDataState)
        .set('temperatureHealthDataState', initHealthDataState)
        .set('vocHealthDataState', initHealthDataState)
        .set('list', List())
}

function applyFetchListSuccess(state, action) {
    const addtionalCo2Data = action.payload.map((item) => ({
        category: "co2",
        measureTime: item.measureTime,
        value: item.densityOfCo2,
    }))
    const addtionalPm1Data = action.payload.map((item) => ({
        category: "pm1",
        measureTime: item.measureTime,
        value: item.densityOfPm1,
    }))
    const addtionalPm10Data = action.payload.map((item) => ({
        category: "pm10",
        measureTime: item.measureTime,
        value: item.densityOfPm10,
    }))
    const addtionalPm25Data = action.payload.map((item) => ({
        category: "pm25",
        measureTime: item.measureTime,
        value: item.densityOfPm25,
    }))
    const addtionalHumidityData = action.payload.map((item) => ({
        category: "humidity",
        measureTime: item.measureTime,
        value: item.humidity,
    }))
    const addtionalTemperatureData = action.payload.map((item) => ({
        category: "temperature",
        measureTime: item.measureTime,
        value: item.temperature,
    }))
    const addtionalVocData = action.payload.map((item) => ({
        category: "voc",
        measureTime: item.measureTime,
        value: item.densityOfVoc,
    }))

    const list = [...state.get('list'), ...action.payload];
    const co2Data = [...state.getIn(co2HealthDataState.list), ...addtionalCo2Data];
    const pm1Data = [...state.getIn(pm1HealthDataState.list), ...addtionalPm1Data];
    const pm10Data = [...state.getIn(pm10HealthDataState.list), ...addtionalPm10Data];
    const pm25Data = [...state.getIn(pm25HealthDataState.list), ...addtionalPm25Data];
    const humidityData = [...state.getIn(humidityHealthDataState.list), ...addtionalHumidityData];
    const temperatureData = [...state.getIn(temperatureHealthDataState.list), ...addtionalTemperatureData];
    const vocData = [...state.getIn(vocHealthDataState.list), ...addtionalVocData];

    return state
        .set('list', List().merge(list))
        .setIn(co2HealthDataState.list, co2Data)
        .setIn(pm1HealthDataState.list, pm1Data)
        .setIn(pm10HealthDataState.list, pm10Data)
        .setIn(pm25HealthDataState.list, pm25Data)
        .setIn(humidityHealthDataState.list, humidityData)
        .setIn(temperatureHealthDataState.list, temperatureData)
        .setIn(vocHealthDataState.list, vocData)
}
function applyCalculateChartConfig(state, action) {

    const co2Values = action.co2Data.map(item => item.value);
    const co2averageValue = Math.round(co2Values.reduce((sum, value) => sum + value, 0) / co2Values.length);
    const co2yMin = Math.min(...co2Values);
    const co2yMax = Math.max(...co2Values);
    let co2yRange = Math.round(
        Math.max((co2averageValue - co2yMin, co2yMax - co2averageValue) / 3)
    );

    const pm1Values = action.pm1Data.map(item => item.value);
    const pm1averageValue = Math.round(pm1Values.reduce((sum, value) => sum + value, 0) / pm1Values.length);
    const pm1yMin = Math.min(...pm1Values);
    const pm1yMax = Math.max(...pm1Values);
    let pm1yRange = Math.round(
        Math.max((pm1averageValue - pm1yMin, pm1yMax - pm1averageValue) / 3)
    );

    const pm10Values = action.pm10Data.map(item => item.value);
    const pm10averageValue = Math.round(pm10Values.reduce((sum, value) => sum + value, 0) / pm10Values.length);
    const pm10yMin = Math.min(...pm10Values);
    const pm10yMax = Math.max(...pm10Values);
    let pm10yRange = Math.round(
        Math.max((pm10averageValue - pm10yMin, pm10yMax - pm10averageValue) / 3)
    );

    const pm25Values = action.pm25Data.map(item => item.value);
    const pm25averageValue = Math.round(pm25Values.reduce((sum, value) => sum + value, 0) / pm25Values.length);
    const pm25yMin = Math.min(...pm25Values);
    const pm25yMax = Math.max(...pm25Values);
    let pm25yRange = Math.round(
        Math.max((pm25averageValue - pm25yMin, pm25yMax - pm25averageValue) / 3)
    );

    const humidityValues = action.humidityData.map(item => item.value);
    const humidityaverageValue = Math.round(humidityValues.reduce((sum, value) => sum + value, 0) / humidityValues.length);
    const humidityyMin = Math.min(...humidityValues);
    const humidityyMax = Math.max(...humidityValues);
    let humidityyRange = Math.round(
        Math.max((humidityaverageValue - humidityyMin, humidityyMax - humidityaverageValue) / 3)
    );

    const temperatureValues = action.temperatureData.map(item => item.value);
    const temperatureaverageValue = Math.round(temperatureValues.reduce((sum, value) => sum + value, 0) / temperatureValues.length);
    const temperatureyMin = Math.min(...temperatureValues);
    const temperatureyMax = Math.max(...temperatureValues);
    let temperatureyRange = Math.round(
        Math.max((temperatureaverageValue - temperatureyMin, temperatureyMax - temperatureaverageValue) / 3)
    );

    const vocValues = action.vocData.map(item => item.value);
    const vocaverageValue = Math.round(vocValues.reduce((sum, value) => sum + value, 0) / vocValues.length);
    const vocyMin = Math.min(...vocValues);
    const vocyMax = Math.max(...vocValues);
    let vocyRange = Math.round(
        Math.max((vocaverageValue - vocyMin, vocyMax - vocaverageValue) / 2)
    );

    if(!co2yRange) co2yRange=2;
    if(!pm1yRange) pm1yRange=2;
    if(!pm10yRange) pm10yRange=2;
    if(!pm25yRange) pm25yRange=2;
    if(!humidityyRange) humidityyRange=2;
    if(!temperatureyRange) temperatureyRange=2;
    if(!vocyRange) vocyRange=2;

    return state
        .setIn(co2HealthDataState.averageValue, co2averageValue)
        .setIn(co2HealthDataState.yMin, co2yMin)
        .setIn(co2HealthDataState.yMax, co2yMax)
        .setIn(co2HealthDataState.yRange, co2yRange)

        .setIn(pm1HealthDataState.averageValue, pm1averageValue)
        .setIn(pm1HealthDataState.yMin, pm1yMin)
        .setIn(pm1HealthDataState.yMax, pm1yMax)
        .setIn(pm1HealthDataState.yRange, pm1yRange)

        .setIn(pm10HealthDataState.averageValue, pm10averageValue)
        .setIn(pm10HealthDataState.yMin, pm10yMin)
        .setIn(pm10HealthDataState.yMax, pm10yMax)
        .setIn(pm10HealthDataState.yRange, pm10yRange)

        .setIn(pm25HealthDataState.averageValue, pm25averageValue)
        .setIn(pm25HealthDataState.yMin, pm25yMin)
        .setIn(pm25HealthDataState.yMax, pm25yMax)
        .setIn(pm25HealthDataState.yRange, pm25yRange)

        .setIn(humidityHealthDataState.averageValue, humidityaverageValue)
        .setIn(humidityHealthDataState.yMin, humidityyMin)
        .setIn(humidityHealthDataState.yMax, humidityyMax)
        .setIn(humidityHealthDataState.yRange, humidityyRange)

        .setIn(temperatureHealthDataState.averageValue, temperatureaverageValue)
        .setIn(temperatureHealthDataState.yMin, temperatureyMin)
        .setIn(temperatureHealthDataState.yMax, temperatureyMax)
        .setIn(temperatureHealthDataState.yRange, temperatureyRange)

        .setIn(vocHealthDataState.averageValue, vocaverageValue)
        .setIn(vocHealthDataState.yMin, vocyMin)
        .setIn(vocHealthDataState.yMax, vocyMax)
        .setIn(vocHealthDataState.yRange, vocyRange)
}

function applyUpdateHealthData(state, action) {

    return state
    // .set('list', List().merge(action.payload))
    // .setIn(co2HealthDataState.list, co2Data)
    // .setIn(pm1HealthDataState.list, pm1Data)
    // .setIn(pm10HealthDataState.list, pm10Data)
    // .setIn(pm25HealthDataState.list, pm25Data)
    // .setIn(humidityHealthDataState.list, humidityData)
    // .setIn(temperatureHealthDataState.list, temperatureData)
    // .setIn(vocHealthDataState.list, vocData)
}
function applyChangeValue(state, action) {
    return state.set(action.prop, action.value)
}

function applyChangePage(state, action) {
    return state.set(action.prop, action.value)
}

function applyChangeEdgeboxStateValue(state, action) {
    return state.setIn(edgeboxState[action.prop], action.value)
}


function applySetLoading(state, action) {
    return state.set(action.prop, action.value)
}

export default combineReducers({
    index: indexReducer,
    indexPager: pagerReducerFor(scope),
});