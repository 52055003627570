import createActionPagerFor from "../common/pagination/actionFor";

const scope = 'WORKPLACE_STATUS';
const actions = {
	/**
	 * Define Action Types
	 */

	// Initialize
	INIT_SEARCH                 : `${scope}_INIT_SEARCH`,
	INIT_DATA                 : `${scope}_INIT_DATA`,
	INIT_MODAL_DATA                 : `${scope}_INIT_MODAL_DATA`,
	INIT_SEARCH_SELECT                 : `${scope}_INIT_SEARCH_SELECT`,

	CHANGE_VALUE                 : `${scope}_CHANGE_VALUE`,
	CHANGE_SEARCH_VALUE                 : `${scope}_CHANGE_SEARCH_VALUE`,
	CHANGE_MODAL_SEARCH_VALUE                 : `${scope}_CHANGE_MODAL_SEARCH_VALUE`,
	CHANGE_SEARCH_DATE_VALUE                 : `${scope}_CHANGE_SEARCH_DATE_VALUE`,
	CHANGE_DETAIL_SEARCH_VALUE                 : `${scope}_CHANGE_DETAIL_SEARCH_VALUE`,

	CHANGE_WORKPLACE_ID_VALUE                 : `${scope}_CHANGE_WORKPLACE_ID_VALUE`,

	FETCH_LIST                 : `${scope}_FETCH_LIST`,
	FETCH_LIST_SUCCESS                 : `${scope}_FETCH_LIST_SUCCESS`,
	FETCH_LIST_DETAIL                 : `${scope}_FETCH_LIST_DETAIL`,
	FETCH_LIST_DETAIL_SUCCESS                 : `${scope}_FETCH_LIST_DETAIL_SUCCESS`,
	FETCH_LIST_MAIN                 : `${scope}_FETCH_LIST_MAIN`,
	FETCH_LIST_MAIN_SUCCESS                 : `${scope}_FETCH_LIST_MAIN_SUCCESS`,
	FETCH_LIST_KIOSK                 : `${scope}_FETCH_LIST_KIOSK`,
	FETCH_LIST_KIOSK_SUCCESS                 : `${scope}_FETCH_LIST_KIOSK_SUCCESS`,
	// 스마트 워치 API 호출
	FETCH_LIST_SMART_WATCH                 : `${scope}_FETCH_LIST_SMART_WATCH`,
	// 호출한 스마트워치 API 결과를 상태 변수로 저장
	FETCH_LIST_SMART_WATCH_SUCCESS                 : `${scope}_FETCH_LIST_SMART_WATCH_SUCCESS`,

	// 스마트 방석 API 호출
	FETCH_LIST_SMART_CHAIR                : `${scope}_FETCH_LIST_SMART_CHAIR`,
	// 호출한 스마트방석 API 결과를 상태 변수로 저장
	FETCH_LIST_SMART_CHAIR_SUCCESS                 : `${scope}_FETCH_LIST_SMART_CHAIR_SUCCESS`,

	FETCH_LIST_EDGE_BOX                 : `${scope}_FETCH_LIST_EDGE_BOX`,
	FETCH_LIST_EDGE_BOX_SUCCESS                 : `${scope}_FETCH_LIST_EDGE_BOX_SUCCESS`,
	FETCH_LIST_EDGE_BOX_MODAL                 : `${scope}_FETCH_LIST_EDGE_BOX_MODAL`,
	FETCH_LIST_EDGE_BOX_MODAL_SUCCESS                 : `${scope}_FETCH_LIST_EDGE_BOX_MODAL_SUCCESS`,
	FETCH_WORKPLACE_INFO                 : `${scope}_FETCH_WORKPLACE_INFO`,
	FETCH_WORKPLACE_INFO_SUCCESS                 : `${scope}_FETCH_WORKPLACE_INFO_SUCCESS`,

	SET_LOADING                 : `${scope}_SET_LOADING`,


	/** initialize **/
	initData: () => ({
		type: actions.INIT_DATA,
	}),
	initModalData: () => ({
		type: actions.INIT_MODAL_DATA,
	}),
	initSearch: () => ({
		type: actions.INIT_SEARCH,
	}),
	initSelectValue: () => ({
		type: actions.INIT_SEARCH_SELECT,
	}),

	changeValue: (type, prop, value) => ({
		type: actions[type],
		prop,
		value,
	}),

	fetchList: (conditions) => ({
		type: actions.FETCH_LIST,
		conditions,
	}),

	fetchListSuccess: (payload) => ({
		type: actions.FETCH_LIST_SUCCESS,
		payload,
	}),

	fetchListDetail: (conditions) => ({
		type: actions.FETCH_LIST_DETAIL,
		conditions,
	}),


	fetchListDetailSuccess: (payload) => ({
		type: actions.FETCH_LIST_DETAIL_SUCCESS,
		payload,
	}),

	fetchWorkplaceInfo: (conditions) => ({
		type: actions.FETCH_WORKPLACE_INFO,
		conditions,
	}),

	fetchWorkplaceInfoSuccess: (payload) => ({
		type: actions.FETCH_WORKPLACE_INFO_SUCCESS,
		payload,
	}),


	fetchListMain: (conditions) => ({
		type: actions.FETCH_LIST_MAIN,
		conditions,
	}),

	fetchListMainSuccess: (payload) => ({
		type: actions.FETCH_LIST_MAIN_SUCCESS,
		payload,
	}),

	fetchListKiosk: (conditions) => ({
		type: actions.FETCH_LIST_KIOSK,
		conditions,
	}),

	fetchListKioskSuccess: (payload) => ({
		type: actions.FETCH_LIST_KIOSK_SUCCESS,
		payload,
	}),

	// 스마트 워치 api 호출
	fetchListSmartWatch: (conditions) => ({
		type: actions.FETCH_LIST_SMART_WATCH,
		conditions,
	}),

	// 응답받은 워치 api 데이터 상태 저장
	fetchListSmartWatchSuccess: (payload) => ({
		type: actions.FETCH_LIST_SMART_WATCH_SUCCESS,
		payload,
	}),

	// 스마트 방석 api 호출
	fetchListSmartChair: (conditions) => ({
		type: actions.FETCH_LIST_SMART_CHAIR,
		conditions,
	}),

	// 응답받은 방석 api 데이터 상태 저장
	fetchListSmartChairSuccess: (payload) => ({
		type: actions.FETCH_LIST_SMART_CHAIR_SUCCESS,
		payload,
	}),

	fetchListEdgebox: (conditions) => ({
		type: actions.FETCH_LIST_EDGE_BOX,
		conditions,
	}),

	fetchListEdgeboxSuccess: (payload) => ({
		type: actions.FETCH_LIST_EDGE_BOX_SUCCESS,
		payload,
	}),

	fetchListEdgeboxModal: (conditions) => ({
		type: actions.FETCH_LIST_EDGE_BOX_MODAL,
		conditions,
	}),

	fetchListEdgeboxModalSuccess: (payload) => ({
		type: actions.FETCH_LIST_EDGE_BOX_MODAL_SUCCESS,
		payload,
	}),

	setLoading: (prop, value) => ({
		type: actions.SET_LOADING,
		prop,
		value,
	}),

	// Page
	setListPager: createActionPagerFor(scope),
	setListPagerModal: createActionPagerFor('DEVICE_MODAL'),
	setListEdgeBoxPager: createActionPagerFor('EDGE_BOX'),
};

export default actions;
export {
	scope
}