import {call, put, spawn, takeEvery} from 'redux-saga/effects';
import {spinnerActions} from 'gurmwi-share-front/share/spinner';
import actions from './actions';
import * as service from './serviceApi';
import {infoMessage} from "gurmwi-share-front/share/feedback/Message/message";
import {createPagerPayload} from "../../helpers/utility";

export function* fetchListEdgeboxSaga(action) {
  yield put(spinnerActions.start());
  try {
    const response = yield call(service.fetchListEdgebox, action.conditions);
    yield put(actions.changeValue("CHANGE_VALUE_DEVICE_INFO_EDGEBOX", 'loading', false))
    if (!response.data.items) {
      infoMessage('데이터가 존재하지 않습니다.');
    }
    yield put(actions.setListPagerEdgebox(createPagerPayload(response.data)));
    yield put(actions.fetchListSuccessEdgebox(response.data.items));
    yield put(spinnerActions.success());
  } catch (error) {
    yield put(actions.changeValue("CHANGE_VALUE_DEVICE_INFO_EDGEBOX", 'loading', false))
    yield put(spinnerActions.failure());
    // errorModal('목록 조회', error);
  }
}
export function* fetchListSmartwatchSaga(action) {
  yield put(spinnerActions.start());
  try {
    const response = yield call(service.fetchListSmartwatch, action.conditions);
    yield put(actions.changeValue("CHANGE_VALUE_DEVICE_INFO_SMARTWATCH", 'loading', false))
    yield put(actions.setListPagerSmartwatch(createPagerPayload(response.data)));
    if (!response.data.items) {
      infoMessage('데이터가 존재하지 않습니다.');
    }
    yield put(actions.fetchListSuccessSmartwatch(response.data.items));
    yield put(spinnerActions.success());
  } catch (error) {
    yield put(actions.changeValue("CHANGE_VALUE_DEVICE_INFO_SMARTWATCH", 'loading', false))
    yield put(spinnerActions.failure());
    // errorModal('목록 조회', error);
  }
}
export function* fetchListKioskboxSaga(action) {
  yield put(spinnerActions.start());
  try {
    const response = yield call(service.fetchListKiosk, action.conditions);
    yield put(actions.changeValue("CHANGE_VALUE_DEVICE_INFO_KIOSK", 'loading', false))
    if (!response.data.items) {
      infoMessage('데이터가 존재하지 않습니다.');
    }
    yield put(actions.setListPagerKiosk(createPagerPayload(response.data)));
    yield put(actions.fetchListSuccessKiosk(response.data.items));
    yield put(spinnerActions.success());
  } catch (error) {
    yield put(actions.changeValue("CHANGE_VALUE_DEVICE_INFO_KIOSK", 'loading', false))
    yield put(spinnerActions.failure());
    // errorModal('목록 조회', error);
  }
}

export function* watchSaga() {
  yield takeEvery(actions.FETCH_LIST_EDGEBOX, fetchListEdgeboxSaga);
  yield takeEvery(actions.FETCH_LIST_SMARTWATCH, fetchListSmartwatchSaga);
  yield takeEvery(actions.FETCH_LIST_KIOSK, fetchListKioskboxSaga);
}

export default function* rootSaga() {
  yield spawn(watchSaga);
}