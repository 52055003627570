// import axios
// 	// , {context}
// 	from '../../settings/serviceConfig';

/**
 * 서비스 목록 조회
 *
 * @param searchCondition
 */
const lounge = () => {
	// return axios.get(`${context}/lounge`);
};

export {
	lounge,
};